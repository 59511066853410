'use strict';
var _ = require('underscore'),
	Swiper = require('swiper');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var CardGrid = View.extend({
	initialize: function(){
		var slider = new Swiper(this.find('.card-grid__inner'), {
			wrapperClass: 'card-grid__slider',
			nextButton: '.js--slide-right',
			prevButton: '.js--slide-left',
			buttonDisabledClass: 'is-disabled',
			slideClass: 'card-grid__column',
			slidesPerView: 8,
			slidesPerGroup: 8,
			spaceBetween: 20,
			simulateTouch : true,
			roundLengths: true,
			breakpoints: {
			   	2500: {
					slidesPerView: 6,
					slidesPerGroup: 2
				},
				1679: {
					slidesPerView: 4,
					slidesPerGroup: 2
				},
				1023: {
					slidesPerView: 2,
					slidesPerGroup: 2
				},
				566: {
					slidesPerView: 1,
					slidesPerGroup: 1
				}
			}
		});
	}
});

(function(){
	Utils.each(Utils.find('.js--card-grid'), function(elem) {
		new CardGrid( {el: elem} );
	});

})();

module.exports = CardGrid;