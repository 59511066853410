'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var MobileNavigation = View.extend({
	isActive:false,

	events: {
		'click .js--navigation-toggle': 'onNavigationToggle'
	},

	initialize: function(){
		this.navigationInner = this.find('.js--navigation-inner');			
		this.navigationToggleButton = this.find('.js--navigation-toggle');
		this.eventEmitter.on('menu:open', this.forceClose.bind(this) );
		this.bodyRef = Utils.find('body')[0];
	},
	
	onNavigationToggle: function() {
		this.isActive = !this.isActive;
		if ( this.isActive ) {
			this.addClass('is-active');
			this.addClass('is-fixed');
			Utils.addClass(this.bodyRef,'is-fixed');
			Utils.addClass(this.navigationInner, 'is-active');
			setTimeout(function() {
				this.navigationInner.style.display="none";
				this.navigationInner.offsetHeight;
				this.navigationInner.style.display="block";				
			}.bind(this), 3000);
		}
		else {
			this.removeClass('is-active');
			this.removeClass('is-fixed');
			Utils.removeClass(this.bodyRef,'is-fixed');
			Utils.removeClass(this.navigationInner, 'is-active');
		}
	},

	forceClose: function(instanceId) {
		if ( typeof instanceId == "undefined") {}
		else if ( instanceId == this.instanceId ) return false;

		this.isActive = false;
		this.removeClass('is-active');
		this.removeClass('is-fixed');
		Utils.removeClass(this.bodyRef,'is-fixed');
		Utils.removeClass(this.navigationInner, 'is-active');
	}
});

(function(){
	Utils.each(Utils.find('.js--mobile-navigation'), function(elem) {
		new MobileNavigation( {el: elem} );
	});

})();

module.exports = MobileNavigation;