'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;
	
var Quantity = View.extend({

	
	mode:-1,
	maxValue:-1,

	events: {
		'click .js--quantity-add': 'addButtonHandler',
		'click .js--quantity-subtract': 'subtractButtonHandler'
	},
	initialize:function() {

		this.addButton = this.find('.js--quantity-add');
		this.subtractButton = this.find('.js--quantity-subtract');
		this.valueInput = this.find('.js--quantity-value-input');
		this.valueText = this.find('.js--quantity-value-text');
		this.maxValue = this.el.getAttribute('data-maxvalue');
		if ( this.valueInput instanceof HTMLElement) {
			this.valueElement = this.valueInput;
			this.mode = Quantity.MODE.INPUT;
			Utils.on(this.valueElement,'keydown',this.keyDownHandler.bind(this));			
			Utils.on(this.valueElement,'keyup',this.changeHandler.bind(this));			
			Utils.on(this.valueElement,'blur',this.changeHandler.bind(this));			
		}
		else if ( this.valueText ) {
			this.valueElement = this.valueText;
			this.mode = Quantity.MODE.TEXT;
		}
		else {
			throw new Error("NO QUANTITY MODE!!!");
		}

		if ( this.settings.updateCallback ) this.updateCallback = this.settings.updateCallback;
	},
	keyDownHandler:function(event) {
		var x = event.which || event.keyCode;
		if (x == 173 || x == 188 || x == 189 || x == 190) return false;
	},
	changeHandler:function(event) {
		this.controlValue();
	},
	addButtonHandler: function() {
		if ( this.isDisabled(this.addButton) ) return false;
		var val = this.getValue();
		val += 1;
		this.setValue(val);
		this.controlValue();
	},
	subtractButtonHandler: function() {
		if ( this.isDisabled(this.subtractButton) ) return false;
		var val = this.getValue();
		val - 1 >= 0 ? val -= 1 : val = 0;
		this.setValue(val);
		this.controlValue();
	},
	controlValue:function(typing) {
		var val = this.getValue();
		this.setValue(val);

		if (val == 1) {
			Utils.removeClass(this.addButton,'is-disabled');
			Utils.addClass(this.subtractButton,'is-disabled');
		} 
		else {
			Utils.removeClass(this.subtractButton,'is-disabled');
		}
		if (val > this.maxValue) {
			//show error?
		}
		if (val >= this.maxValue) {
			Utils.addClass(this.addButton,'is-disabled');
			this.addClass('is-invalid');
			
			if (!typing) {
				val = this.maxValue;
				this.setValue(val);
			}
		}
		else {
			this.removeClass('is-invalid');
			Utils.removeClass(this.addButton,'is-disabled');
		}
		// Log.db("this.updateCallback??",this.updateCallback)
		if ( this.updateCallback ) this.updateCallback(val);

		if (val > 99) {
			Utils.addClass(this.valueElement,'is-wide');
		} else {
			Utils.removeClass(this.valueElement,'is-wide'); 
		}
	},
	getValue:function() {
		var val;
		if ( this.mode == Quantity.MODE.INPUT ) {
			val = parseInt(this.valueElement.value);
		}
		else if ( this.mode == Quantity.MODE.TEXT ) {
			val = parseInt(this.valueElement.innerHTML);
		}
		else {
			throw new Error("NO QUANTITY MODE!!!");
		}
		return val;
	},
	setValue:function(val) {
		
		if ( this.mode == Quantity.MODE.INPUT ) {
			this.valueElement.value = parseInt(val);
		}
		else if ( this.mode == Quantity.MODE.TEXT ) {			
			this.valueElement.innerHTML = val;
		}
		else {
			throw new Error("NO QUANTITY MODE!!!");
		}
	},
	isDisabled:function(elem) {
		return Utils.hasClass('is-disabled',elem);
	}
});

Quantity.MODE = {INPUT:0,TEXT:1};


module.exports = Quantity;