'use strict';
var _ = require('underscore'),
	moment = require('moment'),
	Pikaday = require('pikaday');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var MiniBasket = View.extend({
	
	isActive:false,
	

	initialize: function(){
		this.triggerButtons = Utils.find('.js--mini-basket-trigger');

		this.components = {
			top: this.find('.js--mini-basket-top'),
			list: this.find('.js--mini-basket-list'),
			calc: this.find('.js--mini-basket-calc'),
			bottom: this.find('.js--mini-basket-bottom'),
		};

		this.htmlRef = Utils.find('html')[0];
		this.eventEmitter.on('menu:open', this.forceClose.bind(this) );		
		this.eventEmitter.on('product:remove', this.resizeHandler.bind(this) );		
		Utils.on(Utils.find('body')[0],'click', this.bodyClick.bind(this));
		//Utils.on(window, 'resize', this.resizeHandler.bind(this) );

		//this.resizeHandler();
	},

	resizeHandler: function() {
		if ( this.components.list instanceof HTMLElement ) {
			var height = this.components.top.offsetHeight + 

						 this.components.calc.offsetHeight + 
						 this.components.bottom.offsetHeight;

			this.components.list.setAttribute('style','max-height: calc(100% - '+height+'px);');
		}
	},

	onToggle: function(event) {
		this.isActive = !this.isActive;
		this.toggleClass('is-active');
		Utils.toggleClass(this.htmlRef,'is-minibasket-mode');
		this.eventEmitter.emit('menu:open', this.instanceId);

		Utils.each(this.triggerButtons, function(elem){
			Utils.toggleClass(elem,'is-active');
		}.bind(this));
	},

	forceClose:function(instanceId) {
		if ( typeof instanceId == "undefined") {}
		else if ( instanceId == this.instanceId ) return false;

		this.removeClass('is-active');
		Utils.removeClass(this.htmlRef,'is-minibasket-mode');
		Utils.each(this.triggerButtons, function(elem){
			Utils.removeClass(elem,'is-active');
		}.bind(this));

		this.isActive = false;
	},

	bodyClick:function(event) {
		var miniBasket = Utils.closestByClass(event.target,'js--mini-basket');
		var miniBasketButton = Utils.closestByClass(event.target,'js--mini-basket-trigger');
		if ( miniBasket ) {
			if ( miniBasketButton ) this.onToggle();
		}
		else {
			this.forceClose();
		}
	}
});

(function(){
	Utils.each(Utils.find('.js--mini-basket'), function(elem) {
		new MiniBasket( {el: elem} );
	});

})();
module.exports = MiniBasket;