'use strict';
var _ = require('underscore'),
	gsap = require('gsap');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;
	
var MemberTest = View.extend({
	triggered:false,
	events: {
		'input .form__item': 'changeHandler'
	},
	initialize:function() {
		this.login = this.find('.js--member-test-login');
	},
	changeHandler: function() {
		if ( this.triggered ) return false;
		this.triggered = true;

		setTimeout(function(){
			TweenLite.to(this.login, 0, {opacity:0});
			this.login.setAttribute('style','display:block;');
			setTimeout(function(){
				TweenLite.from(this.login, 0.5, {opacity:0, ease: Expo.easeOut});
			}.bind(this), 50);
		}.bind(this), 1000);
	}
});

(function(){
	Utils.each(Utils.find('.js--member-test'), function(elem) {
		new MemberTest( {el: elem} );
	});

})();

module.exports = MemberTest;