'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var Swappable = View.extend({

	events: {
		'click .js--swap':'swapHandler'
	},
	initialize:function() {
		this.defaultRef = this.find('.js--default');
		this.replacementRef = this.find('.js--replacement');				
		this.replacementRef.setAttribute('style','display:none;');
	},
	swapHandler: function(event) {
		this.defaultRef.setAttribute('style','display:none;');
		this.replacementRef.setAttribute('style','display:block;position:relative;top:-20px;opacity:0;-webkit-transition:all 0.2s ease-out;-moz-transition:all 0.2s ease-out;-o-transition:all 0.2s ease-out;transition:all 0.2s ease-out;');
		setTimeout(function(){
			this.replacementRef.setAttribute('style','display:block;position:relative;top:0;opacity:1;-webkit-transition:all 0.2s ease-out;-moz-transition:all 0.2s ease-out;-o-transition:all 0.2s ease-out;transition:all 0.2s ease-out;');	
		}.bind(this),50);
	}
});

(function(){
	Utils.each(Utils.find('.js--swappable'), function(elem) {
		new Swappable( {el: elem} );
	});

})();

module.exports = Swappable;