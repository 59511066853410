'use strict';
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var DatePickerXDSoft = require('../components/datepicker-xdsoft'),
	DatePickerPikaday = require('../components/datepicker-pikaday'),
	Choices = require('choices.js');

var Forms = View.extend({

	initialize:function(){

		Utils.each( Utils.find(this.el,'.form__field'), function(elem) { //if a whole form is passed
			if ( !Utils.hasClass(elem,'.is-bound') ) {
					
				if(Utils.hasClass(elem,'form__field--select')) {
					if(Utils.hasClass(elem,'form__field--select--choices')) {
						if (Utils.hasClass(elem,'is-custom')) {
							//do nothing
						}
						else if(Utils.hasClass(elem,'form__field--select--choices--template')) {
							new SelectChoicesTemplate({el:elem});							
						}
						else {
							new SelectChoices({el:elem});
						}
					}
					else {
						new Select({el:elem});
					}
				}
				if(Utils.hasClass(elem,'form__field--radio')) {
					new Radio({el:elem});
				}				
				if(Utils.hasClass(elem,'form__field--textarea')) {
					new Textarea({el:elem});
				}
				if(Utils.hasClass(elem,'form__field--checkbox')) {
					new CheckBox({el:elem});
				}				
				if(Utils.hasClass(elem,'form__field--text')) {
					new Text({el:elem});
				}
				if(Utils.hasClass(elem,'form__field--file')) {
					new FileUpload({el:elem});
				}
				if(Utils.hasClass(elem,'form__field--date')) {
					if (Utils.hasClass(elem,'is-custom')) {
						//do nothing
					}
					else if (Utils.hasClass(elem,'form__field--date--pikaday')) {
						new DatePickerPikaday({el:elem});
					}
					else if (Utils.hasClass(elem,'form__field--date--xdsoft')) {
						new DatePickerXDSoft({el:elem});
					}
					else {
						//default option
						new DatePickerPikaday({el:elem});
					}
				}
			}
		}.bind(this));
	}

	
});

//Select
var Select = View.extend({
	currentValue:'default',
	events: {
		'focus .form__item': 'onFocus',
		'blur .form__item': 'onBlur',
		'change .form__item': 'onChange',
		'select .form__item': 'onSelect'
	},
	initialize: function() {
		this.addClass('is-bound');
		this.formItem = this.find('.form__item');
		if ( !this.hasClass('is-filled') ) {
			this.formItem.selectedIndex = -1;
		}
	},
	onFocus: function() {
		if (this.hasClass('is-disabled')) return;
		this.addClass('has-focus');
		this.addClass('is-filled');
	},
	onChange:function() {
		this.formItem.blur();
		this.onBlur();
	},
	onSelect:function() {
		this.formItem.blur();
		this.onBlur();
	},
	onBlur: function() {
		this.removeClass('has-focus');
		if ( this.formItem.selectedIndex >= 0 ) {
			this.currentValue = this.formItem.options[this.formItem.selectedIndex].value;
		}
		else {
			this.removeClass('is-filled');
		}

		if ( this.currentValue == 'default' ) {
			this.removeClass('is-filled');
		}
	}
});

var SelectChoices = View.extend({
	events: {
	},
	initialize:function() {
		this.selectElement = this.find('.js--select');

		if ( !(this.selectElement instanceof HTMLElement) ) {
			throw new Error('.js--select element not found.');
		}
		this.choices = new Choices( this.selectElement, { 
			searchEnabled: false,
			itemSelectText: ''
		});

		setTimeout(function(){
			Utils.addClass(this.find('.choices__list--single'),'is-ready');
		}.bind(this),400);
		
	}
});




var SelectChoicesTemplate = View.extend({

	events: {

	},

	initialize:function() {
		this.selectElement = this.find('.js--select');

		if ( !(this.selectElement instanceof HTMLElement) ) {
			throw new Error('.js--select element not found.');
		}


		var assetType = '';
		if (this.el.getAttribute('data-type') ) {
			assetType = this.el.getAttribute('data-type');
		}
		else {
			throw new Error('Choices needs type, either data-type="images" or data-type="colors".');
		}
		
		var assetList = [];
		var options = this.find('option');

		Utils.each(options, function(elem){
			assetList.push(elem.getAttribute('data-asset'));
		}.bind(this));
		if ( assetList.length === 0 ){
			throw new Error('No assets found for options. Data is incomplete.');
		}


		if ( this.selectElement instanceof HTMLElement ) {
			var choices = new Choices(this.selectElement, {
				searchEnabled: false,
				itemSelectText: '',
				placeholderValue: 'Søg…',
				shouldSort: false,
				callbackOnCreateTemplates: function(strToEl) {
					
					var classNames = this.config.classNames;
					var itemSelectText = this.config.itemSelectText;
										
					
					return {
					
						item: function(data) {
							var extraSpan = '<span class="choices__item__type">';
							if ( assetType === 'images' ) {
								extraSpan += '<img class="choices__item__type--image" src="'+assetList[data.choiceId-1]+'"/>';
							} else if ( assetType === 'colors' ) {
								extraSpan += '<span class="choices__item__type--color" style="background-color:'+assetList[data.choiceId-1]+';"/>';
							}
							extraSpan += '</span>'
							return strToEl('\
							<div\
								class="'+ String(classNames.item) + ' ' + String(data.highlighted ? classNames.highlightedState : classNames.itemSelectable) + '"\
								data-item\
								data-id="'+ String(data.id) + '"\
								data-value="'+ String(data.value) + '"\
								'+ String(data.active ? 'aria-selected="true"' : '') + '\
								'+ String(data.disabled ? 'aria-disabled="true"' : '') + '\
								>\
								' + extraSpan + String(data.label) +'\
							</div>\
							');
						},

						choice: function(data) {
							var extraSpan = '<span class="choices__item__type">';
							if ( assetType === 'images' ) {
								extraSpan += '<img class="choices__item__type--image" src="'+assetList[data.id-1]+'"/>';
							} else if ( assetType === 'colors' ) {
								extraSpan += '<span class="choices__item__type--color" style="background-color:'+assetList[data.id-1]+';"/>';
							}
							extraSpan += '</span>'
							return strToEl('\
							<div\
								class="'+ String(classNames.item) + ' ' + String(classNames.itemChoice) + ' ' + String(data.disabled ? classNames.itemDisabled : classNames.itemSelectable) + '"\
								data-select-text="'+ String(itemSelectText) + '"\
								data-choice \
								'+ String(data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable') + '\
								data-id="'+ String(data.id) + '"\
								data-value="'+ String(data.value) + '"\
								'+ String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"') + '\
								>\
								' + extraSpan + String(data.label) + '\
							</div>\
							');
						},
					};
				}
			});

			setTimeout(function(){
				Utils.addClass(this.find('.choices__list--single'),'is-ready');
			}.bind(this),400);
		}
	}
});


//Radio
var Radio = View.extend({
	events: {
		'click .form__item': 'onClick'
	},
	initialize: function(){
		this.addClass('is-bound');
		this.formItem = this.find('.form__item');
		if ( this.formItem.checked || this.hasClass('is-checked') ) {
			this.formItem.setAttribute('checked','checked');
			this.formItem.checked = 'checked';
		}
		this.eventEmitter.on('radio:change', this.onChange.bind(this) );
	},
	onClick: function(e) {
		this.eventEmitter.emit('radio:change',e , this.formItem.name);
	},
	onChange: function(e, name){
		if ( this.formItem.name === name ) {
			this.toggleClass('is-checked', this.formItem.checked);
			if ( this.formItem.checked ) this.formItem.setAttribute('checked','checked');
			else this.formItem.removeAttribute('checked');
		}
	}
});


//CheckBox
var CheckBox = View.extend({
	events: {
		'click .form__item': 'onClick'
	},
	initialize: function(){
		this.addClass('is-bound');
	},
	onClick: function(e){
	}
});


//Textarea
var Textarea = View.extend({
	events: {
		'focus .form__item': 'onFocus',
		'blur .form__item': 'onBlur'
	},
	initialize: function() {
		this.addClass('is-bound');
		this.formItem = this.find('.form__item');
		setTimeout(function(){
			if ( this.formItem.value.length > 0 ) this.addClass('is-filled');
		}.bind(this),10);
	},
	onFocus: function() {
		if (this.hasClass('is-disabled')) return;
		this.addClass('has-focus');
		this.addClass('is-filled');
	},
	onBlur: function() {
		this.removeClass('has-focus');
		if ( this.formItem.value.length == 0) this.removeClass('is-filled');
	}
});

//Text
var Text = View.extend({
	events: {
		'focus .form__item': 'onFocus',
		'blur .form__item': 'onBlur'
	},
	initialize: function() {
		this.addClass('is-bound');
		this.formItem = this.find('.form__item');
		setTimeout(function(){
			if ( this.formItem.value.length > 0 ) this.addClass('is-filled');
		}.bind(this),10);
	},
	onFocus: function() {
		if (this.hasClass('is-disabled')) return;
		this.addClass('has-focus');
		this.addClass('is-filled');
	},
	onBlur: function() {
		this.removeClass('has-focus');
		if ( this.formItem.value.length == 0 && this.formItem.getAttribute('placeholder') == null) this.removeClass('is-filled');
	}
});


//FileUpload
var FileUpload = View.extend({
	events: {
		'change .form__item': 'changeHandler'
	},
	initialize: function() {
		this.addClass('is-bound');
		this.formItem = this.find('.form__item');
		this.multipleCaption = this.formItem.getAttribute('data-multiplecaption');
		this.fileNames = this.find('.file-names');		
	},
	changeHandler:function(event) {
		var fileName = '';
		var fileList = this.formItem.files;
		if( fileList && fileList.length > 1 ) {
			fileName = ( this.multipleCaption || '' ).replace( '{count}', fileList.length );
		}
		else {
			fileName = event.target.value.split( '\\' ).pop();
		}

		if( fileName ) this.fileNames.text = fileName;
	}
});


(function(){
	function init() {
		Utils.each( Utils.find('.form'), function(elem) {
			new Forms({el: elem});
		});
	}
	init();
	window.addEventListener('forms:init', init);
})();


module.exports = Forms;