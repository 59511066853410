'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;


var DropDown = View.extend({
	
	isActive:false,
	
	initialize: function() {
		this.triggerButton = this.find('.js--dropdown-trigger');
		this.dropdown = this.find('.dropdown');
		this.eventEmitter.on('menu:open', this.forceClose.bind(this) );
		this.eventEmitter.on('navigation:close', this.forceClose.bind(this) );
		Utils.on(Utils.find('body')[0],'click', this.bodyClick.bind(this));
	},
	onToggle: function(event) {
		this.isActive = !this.isActive;
		Utils.toggleClass(this.dropdown,'is-active');
		Utils.toggleClass(this.triggerButton,'is-active');
		
		if ( this.isActive ) {
			this.eventEmitter.emit('menu:open', this.instanceId);
		}
	},
	forceClose: function(instanceId) {
		if ( typeof instanceId == "undefined") {}
		else if ( instanceId == this.instanceId ) return false;

		this.isActive = false;
		if ( this.dropdown instanceof HTMLElement ) {
			Utils.removeClass(this.dropdown,'is-active');
			Utils.removeClass(this.triggerButton,'is-active');
		}
	},

	bodyClick: function(event) {
		
		var dropdown = Utils.closestByClass(event.target,'js--has-dropdown');
		var dropdownButton = Utils.closestByClass(event.target,'js--dropdown-trigger');
		if ( dropdown && dropdown === this.el ) {
			if ( dropdownButton ) this.onToggle();
		}
		else {
			this.forceClose();
		}
	}
});


(function(){
	Utils.each(Utils.find('.js--has-dropdown'), function(elem) {
		new DropDown( {el: elem} );
	});
})();


var DropDownList = View.extend({

	events: {
		'click .button': 'onItemClick'
	},

	initialize: function() {
		this.dropdownItems = this.find('.dropdown__item .button');		

		this.dropDownTargetId = this.el.getAttribute('data-dropdowntargetid');
		this.associatedTarget = Utils.find('.js--dropdown-list-target[data-dropdowntargetid="'+this.dropDownTargetId+'"]')[0];

	},

	onItemClick: function(event) {
		var button = event.currentTarget;
		if ( this.associatedTarget instanceof HTMLElement ) {
			var clickedText = Utils.find(button, '.text')[0];
			this.associatedTarget.textContent = clickedText.textContent;
		}

		Utils.each(this.dropdownItems,function(elem){
			Utils.removeClass(elem, 'is-active');
		});
		Utils.addClass(button, 'is-active');
	}
});


(function(){
	Utils.each(Utils.find('.js--dropdown-list'), function(elem) {
		new DropDownList( {el: elem} );
	});
})();


module.exports = DropDown;