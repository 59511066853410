'use strict';
var _ = require('underscore'),
	moment = require('moment'),
	Pikaday = require('pikaday');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils,
	Forms = require('../components/forms'),

	ModalFlow = require('../components/modal-flow'),
	AccountModal = require('../components/account-modal'),
	MapsPeopleMap = require('../components/maps-people-map'),
	Overlay = require('../components/overlay');


//@ Param
// content: [String / jQuery / Html] Content to be shown in modalbox
// header: [String] Header caption
// showHeader: [bool] show or hide the header
// showFooter: [bool] show or hide the footer
// render: [function] public function that opens the modalbox
// remove: [function] public function that closes the modalbox
// onOk: [function] cb function triggered on ok click
// onCancel: [function] cb function triggered on cancel click
// onClose: [function] cb function triggered on close click
//@ end

var ModalBox = View.extend({
	
	viewName:'ModalBox',

	className: 'modal',
	
	events: {
		'click .js--close-modal': 'onClose'
	},

	initialize: function() {
		var defaultTemplateContent = Utils.find('#modalTemplate')[0].innerHTML;
		this.el = Utils.createEl('div'+'.'+this.className+' '+this.settings.cssClasses);
		this.el.innerHTML = defaultTemplateContent;
		this.find('.modal__content').innerHTML = this.settings.content;		
		this.overlay = new Overlay({ isFixed: true, fixBody: this.settings.fixBody });		
		this.eventEmitter.on('overlay:clicked', this.close.bind(this));		
	},
	render: function(e) {
		this.overlay.render();		
		this.overlay.append(this.el);
		this.eventEmitter.emit('modalbox:rendered', this, e);

		Utils.each( Utils.find('.js--modal-flow'), function(elem) {
			new ModalFlow( {el: elem} );
		});
		Utils.each( Utils.find('.js--account-modal'), function(elem) {
			new AccountModal( {el: elem} );
		});
		Utils.each( Utils.find('.form'), function(elem) {
			new Forms({el: elem});
		});
		

		function mapsLoadHandler() {
			window.googleMapsLoaded = true;
			Utils.each(Utils.find('.js--maps-people-map'), function(elem) {
				new MapsPeopleMap( {el: elem} );
			});
		}
		if ( window["google"] && !window.googleMapsLoaded ) {
			google.maps.event.addDomListener(window, 'load', mapsLoadHandler);
		}
		else {
			mapsLoadHandler();
		}



		var modalEvent = new CustomEvent('modal:render', { detail: {modal:this} });
		window.dispatchEvent(modalEvent);


		this.frame = this.find('iframe');
		this.modalMiddle = this.find('.modal__middle__inner');

		if ( this.frame instanceof HTMLElement ) {
			this.frame.addEventListener('load', function() {
				this.resizeIframeContent();

				Utils.on(window, 'resize', this.resizeIframeContent.bind(this));
				window.addEventListener('modal:update', this.resizeIframeContent.bind(this));
				
			}.bind(this));
		}
	},

	resizeIframeContent:function(){
		clearTimeout(this.resizeTimeout);
		this.resizeTimeout = setTimeout( function(){ 
			if ( this.frame.contentWindow ) {				
				this.frame.setAttribute('height',this.frame.contentWindow.document.body.offsetHeight);
				this.frame.setAttribute('width', this.modalMiddle.offsetWidth);
			}
		}.bind(this), 100)
	},

	onClose: function(e) {
		Log.fn('ModalBox | onClose');
		e.stopPropagation();
		this.eventEmitter.emit('modal:close');
		this.close();
	},

	close:function() {
		this.remove();
	}

});




(function(){
	function init() {
		Utils.each( Utils.find('.js--open-modal'), function(elem) {
			if ( !Utils.hasClass(elem, 'is-bound') ) {
				
				Utils.addClass(elem, 'is-bound');

				var clickHandler = function() {
					var el = this;
					var template = this.getAttribute('data-template') ? Utils.find('#'+this.getAttribute('data-template'))[0] : null;
					var content = template ? template.innerHTML : document.createElement('<p>TEMPLATE MISSING</p>');
					var fixBody = this.getAttribute('data-fixbody') ? this.getAttribute('data-fixbody') == "true" : false;
					var cssClasses = template.getAttribute('data-classes') ? template.getAttribute('data-classes') : '';
					cssClasses += this.getAttribute('data-classes') ? ' ' + this.getAttribute('data-classes') : '';
					
					var instantiationFail = false;
					if ( typeof template == 'undefined' ) {
						Log.er("ModalBox | no template");
						instantiationFail = true;
					}
					if ( typeof content == 'undefined' ) {
						Log.er("ModalBox | no content");
						instantiationFail = true;
					}
					if ( typeof fixBody == 'undefined' ) {
						Log.er("ModalBox | no fixBody");
						instantiationFail = true;
					}
					if ( typeof cssClasses == 'undefined' ) {
						Log.er("ModalBox | no cssClasses");
						instantiationFail = true;
					}
					if ( instantiationFail ) return false;
					
					else {
						var modal = new ModalBox({ content: content, cssClasses:cssClasses, fixBody:fixBody});
						modal.render();
					}

				}


				Utils.on(elem,'click', clickHandler);
				var triggerDelay = elem.getAttribute('data-triggerdelay');
				// Log.db("triggerDelay??? ",triggerDelay)
				if ( triggerDelay ) {
					setTimeout(function() {
						var event = new Event('click');
						elem.dispatchEvent(event);				
					}, triggerDelay);
				}
			}

		});
	}

	init();
	window.addEventListener('modal:init', init);
})();

module.exports = ModalBox;
