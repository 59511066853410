'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var Suggestions = View.extend({
	markup:'<ul class="suggestions__list"></ul>',
	listActive:false,
	data:'',
	serviceRoot:'',
	servicePath:'',
	currentQuery:'',
	timeoutHandle:'',
	innerEl:null,
	events: {
		'input':'changeHandler'	
	},
	initialize: function() {
		Log.db("settings",this.settings)
		this.serviceRoot = this.el.getAttribute('data-serviceroot');
	},
	changeHandler:function() {
		var query = this.el.value;
		if ( query === this.currentQuery ) return false;
		this.currentQuery = query;
		
		if ( this.timeoutHandle ) clearTimeout(this.timeoutHandle);
		
		this.removeSuggestions();

		this.timeoutHandle = setTimeout(function(){
			this.servicePath = this.serviceRoot +'?q='+ query;
			this.loadResults();
		}.bind(this), 500);
	},

	parseResults:function(){
		Log.db("data",this.data);
		
		this.innerEl = document.createElement('div'),
		this.innerEl.innerHTML = this.markup;
		this.innerEl.classList.add('suggestions');
		var container = this.closestByClass('form__field');
		container.appendChild(this.innerEl);
		Log.db("this.innerEl",this.innerEl)
		this.suggestionsList = Utils.find(this.innerEl,'.suggestions__list')[0];

		var suggestionCount = this.data.length;
		for (var i = 0; i<suggestionCount; i++) {
			var dataItem = this.data[i];
			var suggestionItem = document.createElement('li');
			suggestionItem.classList.add('suggestions__list__item');
			suggestionItem.innerHTML = '<a class="button js--suggestion" data-suggestionlabel="'+dataItem.label+'" data-suggestionid="'+dataItem.id+'"><span class="text">'+dataItem.label+'</a>';
			this.suggestionsList.appendChild(suggestionItem);
			var suggestionItemButton = Utils.find(suggestionItem,'.js--suggestion')[0];
			Utils.on(suggestionItemButton,'click',this.acceptSuggestion.bind(this));
		}

		this.innerEl.setAttribute('style','display:block;');
		setTimeout(function(){Utils.addClass(this.innerEl,'is-active');}.bind(this), 50);

	},

	acceptSuggestion:function(event){
		var button = event.currentTarget;
		this.el.setAttribute('value',button.getAttribute('data-suggestionlabel'));
		this.el.setAttribute('data-suggestionid',button.getAttribute('data-suggestionid'));

		this.removeSuggestions();
	},
	removeSuggestions:function(){
		if ( this.innerEl ) {
			try {
				this.innerEl.parentNode.removeChild(this.innerEl);
			} catch (error){}
		}
	},
	loadResults:function(){
		var request = new XMLHttpRequest();
		request.open('GET', this.servicePath, true);

		request.onload = function() {
			if (request.status >= 200 && request.status < 400) {
				// Success!
				this.data = JSON.parse(request.responseText);
				this.parseResults();
			} 
			else {
	    		// We reached our target server, but it returned an error
	    		Log.err("STATUS ERROR")
			}
		}.bind(this);
			
		request.onerror = function() {
		  // There was a connection error of some sort
		  Log.err("CONNECTION ERROR")
		};

		request.send();
	}
});

(function(){
	Utils.each(Utils.find('.js--suggestions'), function(elem) {
		new Suggestions( {el: elem} );
	});

})();

module.exports = Suggestions;