'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var ProductFilter = View.extend({

	isActive: false,

	events: {
		'click .js--filter-select': 'togglePopout',
		'click .js--filter-close': 'forceClose'
	},

	initialize: function() {
		this.popout = this.find('.js--filter-popout');
		this.trigger = this.find('.js--filter-select');
		Utils.on(Utils.find('body')[0],'click', this.bodyClick.bind(this));
		this.eventEmitter.on('menu:open', this.forceClose.bind(this) );
	},
	
	togglePopout: function(event) {
		this.isActive = !this.isActive;
		Utils.toggleClass(this.popout, 'is-active');
		Utils.toggleClass(this.trigger, 'is-active');

		if ( this.isActive ) {
			this.eventEmitter.emit('menu:open', this.instanceId);
		}
	},

	forceClose: function(instanceId) {
		if ( typeof instanceId == "undefined") {}
		else if ( instanceId == this.instanceId ) return false;

		this.isActive = false;
		if ( this.popout instanceof HTMLElement ) {
			Utils.removeClass(this.popout,'is-active');
			Utils.removeClass(this.trigger, 'is-active');
		}
	},

	bodyClick: function(event) {
		var target = event.target;
		
		var filter = Utils.closestByClass(target,'js--product-filter'),
			toggleButton = Utils.closestByClass(target,'js--filter-select'),
			closeButton = Utils.closestByClass(target,'js--filter-close');

		
		if ( !filter ) {
			this.forceClose(null);
		}
		else if ( toggleButton || closeButton ) {
			// DO NOTHING
		}
		else if ( filter !== this.el ) {
			// 
		}
	}
});

(function(){
	Utils.each(Utils.find('.js--product-filter'), function(elem) {
		new ProductFilter( {el: elem} );
	});

})();

module.exports = ProductFilter;