'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var DeliveryInfo = View.extend({
	initialize: function(){
		//this.textPositive = this.find('.js--text-positive');
		//this.iconPositive = this.find('.js--icon-positive');

		//this.textNegative = this.find('.js--text-negative');
		//this.iconNegative = this.find('.js--icon-negative');

		if ( !this.hasClass('is-active') ) this.eventEmitter.on('delivery:update', this.controlStatus.bind(this) );
	},
	controlStatus(deliveryAvailable) {
		if ( deliveryAvailable ) {
			this.removeClass('is-active');
			setTimeout(function(){
				this.el.setAttribute('style','');				
			}.bind(this),300);	
		}
		else {
			this.el.style.display = 'block';

			setTimeout(function(){
				this.addClass('is-active');
			}.bind(this),20);	
		}

		// Utils.toggleClass(this.iconPositive, 'is-active', deliveryAvailable);

		// Utils.toggleClass(this.textNegative, 'is-active', !deliveryAvailable);
		// Utils.toggleClass(this.iconNegative, 'is-active', !deliveryAvailable);
	}

});

(function(){
	Utils.each(Utils.find('.js--delivery-info'), function(elem) {
		new DeliveryInfo( {el: elem} );
	});
})();
module.exports = DeliveryInfo;