var _ = require('underscore'),
	Forms = require('./components/forms'),
	SearchBar = require('./components/search-bar'),
	MemberTest = require('./components/member-test'),
	DropDown = require('./components/dropdown'),
	ModalBox = require('./components/modalbox'),
	MiniBasket = require('./components/mini-basket'),
	CheckoutBasketParking = require('./components/checkout-basket-parking'),
	BasketTotal = require('./components/basket-total'),
	BasketProduct = require('./components/basket-product'),
	MiniBasketProduct = require('./components/mini-basket-product'),
	Notification = require('./components/notification'),
	CookieCompliance = require('./components/cookie-compliance'),
	//SubNavigation = require('./components/sub-navigation'),
	Accordion = require('./components/accordion'),
	MobileNavigation = require('./components/mobile-navigation'),
	VideoPlayer = require('./components/video-player'),
	PanningMap = require('./components/panning-map'),
	HeroVideo = require('./components/hero-video'),
	SearchAhead = require('./components/search-ahead'),
	ParkingLotPicker = require('./components/parking-lot-picker'),
	//ParkingForm = require('./components/parking-form'),	
	PaymentWithPin = require('./components/payment-with-pin'),	
	ModalFlow = require('./components/modal-flow'),
	CardGrid = require('./components/card-grid'),
	Flights = require('./components/flights'),
	Quantity = require('./components/quantity'),
	ParkingTimePicker = require('./components/parking-time-picker'),
	Tooltip = require('./components/tooltip'),
	Acquisition = require('./components/acquisition'),
	ShopForm = require('./components/shop-form'),
	ProductSlider = require('./components/product-slider'),
	Suggestions = require('./components/suggestions'),
	ParcelBoxes = require('./components/parcel-boxes'),
	// WifiCountries = require('./components/wifi-countries'),
	BasketButton = require('./components/basket-button'),
	AlphabeticList = require('./components/alphabetic-list'),
	DeliveryInfo = require('./components/delivery-info'),
	TabPanes = require('./components/tab-panes'),
	CategoryBrowser = require('./components/category-browser'),
	MapsPeopleMap = require('./components/maps-people-map'),
	ProductFilter = require('./components/product-filter'),
	ProductDetails = require('./components/product-details'),
	StylishTable = require('./components/stylish-table'),
	Swappable = require('./components/swappable'),
	ProductFilterOverlay = require('./components/product-filter-overlay'),
	AnimatedReveal = require('./components/animated-reveal'),
	InjectionTest = require('./components/injection-test'),
	DatePickerXDSoft = require('./components/datepicker-xdsoft'),
	DatePickerPikaday = require('./components/datepicker-pikaday');

!function (root, factory){
	'use strict';
	if (typeof exports === 'object') {
		// CommonJS module
		module.exports = factory;
	} else if (typeof define === 'function' && define.amd) {
		// AMD. Register as an anonymous module.
		define(function ()
		{
			return factory;
		});
	}
	root['Sh'] = root['Sh'] || {};
	_.extend(root['Sh'], factory);{}
	root['Sh'] = factory;


}(window, {
	Forms: Forms,
	SearchBar: SearchBar,
	MemberTest: MemberTest,
	DropDown: DropDown,
	ModalBox: ModalBox, 
	Accordion: Accordion, 
	MiniBasket: MiniBasket, 
	CheckoutBasketParking: CheckoutBasketParking, 
	BasketTotal: BasketTotal, 
	BasketProduct: BasketProduct, 
	Notification: Notification,
	//SubNavigation: SubNavigation,
	CookieCompliance: CookieCompliance,
    MobileNavigation: MobileNavigation,
    VideoPlayer: VideoPlayer,
    HeroVideo: HeroVideo,
    SearchAhead: SearchAhead,
    ParkingLotPicker: ParkingLotPicker,
    ParkingTimePicker: ParkingTimePicker,
    PanningMap: PanningMap,
    ModalFlow: ModalFlow,
    CardGrid: CardGrid,
    Flights: Flights,
    Quantity: Quantity,
    Tooltip: Tooltip,
    Acquisition: Acquisition,
    ShopForm: ShopForm,
    Suggestions: Suggestions,
    ProductSlider: ProductSlider,
    BasketButton: BasketButton,
    AlphabeticList: AlphabeticList,
    // WifiCountries: WifiCountries,
    DeliveryInfo: DeliveryInfo,
    TabPanes: TabPanes,
    CategoryBrowser: CategoryBrowser,
    ProductDetails: ProductDetails,
    PaymentWithPin: PaymentWithPin,
    ProductFilter: ProductFilter,
   	MapsPeopleMap: MapsPeopleMap,
   	StylishTable: StylishTable,
   	Swappable: Swappable,
   	ProductFilterOverlay: ProductFilterOverlay,
   	InjectionTest: InjectionTest,
    DatePickerXDSoft: DatePickerXDSoft,
    DatePickerPikaday: DatePickerPikaday,
    AnimatedReveal: AnimatedReveal
});