'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;
	
var ProductFilterOverlay = View.extend({
	triggered:false,
	events: {
		'click .js--product-filter-overlay-trigger': 'triggerOverlayHandler',
		'click .js--product-filter-overlay-close': 'closeOverlayHandler'
	},
	initialize:function() {
		this.overlayRef = this.find('.js--product-filter-overlay-content');
	},
	triggerOverlayHandler: function() {
		if ( this.overlayOpen ) return false;
		this.overlayOpen = true;

		setTimeout(function(){
			this.overlayRef.setAttribute('style','display:block;');
			setTimeout(function(){Utils.addClass(this.overlayRef,'is-active');}.bind(this), 50);
			Log.db("this.eventEmitter",this.eventEmitter)
			this.eventEmitter.emit('accordion:updateheight');
		}.bind(this), 50);
	},
	closeOverlayHandler: function() {
		this.overlayOpen = false;
		Utils.removeClass(this.overlayRef,'is-active');
		setTimeout(function(){
			this.overlayRef.setAttribute('style','');
		}.bind(this), 300);
	}
});

(function(){
	Utils.each(Utils.find('.js--product-filter-overlay'), function(elem) {
		new ProductFilterOverlay( {el: elem} );
	});

})();

module.exports = ProductFilterOverlay;