'use strict';
var _ = require('underscore'),
	Quantity = require('../components/quantity');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var MiniBasketProduct = View.extend({
		
	events: {		
		'click .js--remove-product': 'onRemove'
	},
	initialize:function() {		
		var quantityEl = this.find('.js--quantity');
		if ( quantityEl instanceof HTMLElement ) {
			this.quantity = new Quantity( {el: quantityEl} );
		}
		
	},
	onRemove:function(event) {
		this.addClass('is-deleted');
		setTimeout(function() { 
			this.testForBasketNotice();
			Utils.remove(this.el);
		}.bind(this), 300);

		this.eventEmitter.emit('product:remove');

	},
	testForBasketNotice: function() {
		var nextElement = this.el.nextElementSibling ? this.el.nextElementSibling : null;
		if ( nextElement instanceof HTMLElement && Utils.hasClass(nextElement, 'basket-notice') ) {
			Utils.remove(nextElement);
		}	
	}
	
});

(function(){
	Utils.each(Utils.find('.js--mini-basket-product'), function(elem) {
		new MiniBasketProduct( {el: elem} );
	});

})();

module.exports = MiniBasketProduct;