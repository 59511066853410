'use strict';
var _ = require('underscore'),
	Choices = require('choices.js');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var Acquisition = View.extend({

	events: {
		'click .js--option-button':'toggleOption',
		'change .js--option-button':'toggleOption'
	},
	initialize:function() {
		this.optionButtons = this.find('.js--option-button');
		this.optionItems = this.find('.js--option-item');

		Utils.each(this.optionItems, function(elem) {
			new AcquisitionOption({el:elem});
		});


	},
	toggleOption: function(event) {
		var button = event.currentTarget;
		if ( Utils.hasClass(button,'is-disabled') ) return false;
		

		var tabId = button.getAttribute('data-tabid');
		var optionItem = this.find('.js--option-item[data-tabid="'+tabId+'"]');
		
		if ( Utils.hasClass(optionItem, 'is-active') ) return false;

		Utils.each(this.optionButtons, function(elem) {
			Utils.removeClass(elem,'is-active');
		});
		Utils.each(this.optionItems, function(elem) {
			Utils.removeClass(elem,'is-active');
			elem.setAttribute('style','');
		});
		Utils.addClass(button,'is-active');

		optionItem.setAttribute('style','display:block;');
		setTimeout(function(){Utils.addClass(optionItem, 'is-active');}.bind(this), 50);

		window.dispatchEvent(new Event('accordion:updateheight'));
	},
});

var AcquisitionOption = View.extend({
	active:false,
	
	initialize:function() {
		this.subOptions = this.find('.suboptions');
		this.tabId = parseInt( this.el.getAttribute('data-tabid') );
		this.el.setAttribute('style','display:block;');

		if ( this.subOptions instanceof HTMLElement ) {
			setTimeout(function(){ this.resetTab(); }.bind(this), 250);
		}
		else {
			this.resetTab();
		}

		this.pickupTimePicker = this.find('.js--time-select');
		if ( this.pickupTimePicker instanceof HTMLElement ) {
			this.choices = new Choices(
				this.pickupTimePicker,
				{ 
					placeholder:false,
					itemSelectText: ''
				}
			);

			setTimeout(function(){
				Utils.addClass(this.find('.choices__list--single'),'is-ready');
			}.bind(this),400);
		}
	},

	resetTab: function() {
		if ( this.tabId != 0 ) {
			this.removeClass('is-active');
			this.el.setAttribute('style','');
		}
		else {
			this.addClass('is-active');
			this.el.setAttribute('style','display:block;');
		}
	}
});

(function(){
	Utils.each(Utils.find('.js--acquisition'), function(elem) {
		new Acquisition( {el: elem} );
	});

})();

module.exports = Acquisition;