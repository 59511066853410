'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var Flights = View.extend({

	events: {
		'click .js--toggle-type':'toggleTypeHandler'
	},
	initialize:function() {
		this.toggleTypeButtons = this.find('.js--toggle-type');
				
	},
	toggleTypeHandler: function(event) {
		Utils.each(this.toggleTypeButtons, function(elem) {
			Utils.removeClass(elem,'is-active');
		});
		var button = event.currentTarget;
		Utils.addClass(button,'is-active');
	}
});

(function(){
	Utils.each(Utils.find('.js--flights'), function(elem) {
		new Flights( {el: elem} );
	});

})();

module.exports = Flights;