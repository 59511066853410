'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var BasketTotal = View.extend({
	
	events: {		
		'click .js--open-coupon-form': 'onOpenCouponForm',
		'click .js--use-coupon': 'onUseCoupon',
		'click .js--remove-coupon': 'onRemoveCoupon'
	},
	initialize: function(){

		return false;

		this.couponForm = this.find('.js--coupon-form');
		this.couponFormField = this.find('.js--coupon-field');
		this.openCouponForm = this.find('.js--open-coupon-form');
		
		this.subCalculationCash = this.find('.js--sub-calculation-cash');
		this.subCalculationPoints = this.find('.js--sub-calculation-points');
		
		if ( this.subCalculationCash.length > 0 && !Utils.hasClass(this.subCalculationCash,'is-active') ) Utils.hideElement(this.subCalculationCash);
		if ( this.subCalculationPoints instanceof HTMLElement ) Utils.hideElement(this.subCalculationPoints);
	},

	onOpenCouponForm:function() {

		return false;
		Utils.hideElement(this.openCouponForm);
		Utils.showElement(this.couponForm);
		this.eventEmitter.emit('accordion:updateheight', this.instanceId);
	},
	
	onUseCoupon:function() {

		return false;
		if ( this.subCalculationPoints instanceof HTMLElement && this.couponFormField.value != ""  ) {
			Utils.showElement(this.subCalculationPoints);			
		}
		else {
			Utils.showElement(this.subCalculationCash);
		}
		this.couponFormField.value = "";
		this.couponFormField.blur();
		this.eventEmitter.emit('accordion:updateheight', this.instanceId);
	},
	onRemoveCoupon:function() {

		return false;
		Utils.hideElement(this.subCalculationCash);
		if ( this.subCalculationPoints instanceof HTMLElement ) Utils.hideElement(this.subCalculationPoints);
		Utils.showElement(this.openCouponForm);
		this.eventEmitter.emit('accordion:updateheight', this.instanceId);
	}	
});

(function(){
	Utils.each(Utils.find('.js--basket-total'), function(elem) {
		new BasketTotal( {el: elem} );
	});
})();
module.exports = BasketTotal;