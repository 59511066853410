var $ = require('jquery'),
	moment = require('moment');

require('../vendor/php-date-formatter.js');

require('jquery-mousewheel/jquery.mousewheel.js')($);
require('jquery-datetimepicker/jquery.datetimepicker.js')($);

var View = require('kerneljs').View,
	Utils = require('kerneljs').Utils,
	Log = require('kerneljs').Log;


var DatePickerXDSoft = View.extend({
	
	datePicker: null,

	hoursPadding: 9,
	events: {
		'focus .form__item': 'onFocus',
		'blur .form__item': 'onBlur',
		'change .form__item':'changeHandler'
	},
	initialize:function() {
		Log.fn("DatePickerXDSoft | initialize");

		if ( !this.hasClass('is-bound') ) {
			
			Log.db("> not yet bound");
			this.formItem = this.find('.form__item');
			this.bodyRef = Utils.find('body')[0];
			this.momentFormat = 'DD / MM / YY';
			var data = {
				value:this.formItem.getAttribute('data-value'),
				maxdate: this.el.getAttribute('data-maxdate'),
				mindate: this.el.getAttribute('data-mindate')
			}

			this.formattedDate = data.value ? data.value.replace(/T.*$/, '') : null; //1990-12-31T23:59:60Z - ex. correct date value format
			var timeOffset = this.formItem.getAttribute('data-servertimeoffset');
			
			this.language =  this.bodyRef.getAttribute('data-language')? this.bodyRef.getAttribute('data-language').split('-')[0] : 'da';
			this.minDate = data.mindate ? data.mindate.replace(/T.*$/, '') : null;
			this.maxDate = data.maxdate ? data.maxdate.replace(/T.*$/, '') : null;

			this.serverTimeOffset = 0; 
			
			if (timeOffset) {
				this.serverTimeOffset = parseInt(timeOffset.split(':')[1]);
			}

			var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
			iOS = false; //To avoid using native

			if (iOS) {
				this.initIOSDatePicker();			
			}
			else {
				this.initDefaultDatePicker();
			}
			this.addClass('is-bound');
		} else {
			Log.db("> already bound");
		}
	},

	initDefaultDatePicker:function(){

		var now = new Date(),
			minTime = now.getUTCHours() + this.serverTimeOffset + this.hoursPadding; // calculating earliest allowed hour (9h ahead of current time)

		this.format = 'd / m / y';
		
		this.datePickerOptions = {
			initTime: false,
			timepicker: false,
			format: this.format,
			todayButton: false,
			scrollMonth: false,
			dayOfWeekStart: 1,
			className: 'datepicker-advanced',
			onSelectDate: this.changeHandler.bind(this),
			onGenerate: this.updatedCalendarHeader
		};
		if ( this.minDate ) this.datePickerOptions.minDate = moment(this.minDate).toDate();
		if ( this.maxDate ) this.datePickerOptions.maxDate = moment(this.maxDate).toDate();
			
		if ( this.formattedDate ) {
			this.addClass('is-filled');
			this.formattedDate = moment(this.formattedDate).toDate();
			this.datePickerOptions.defaultDate = this.formattedDate;
			this.formItem.value = moment(this.formattedDate).format(this.momentFormat);
		}
		
		$.datetimepicker.setLocale(this.language);

		this.datePicker = $(this.formItem).datetimepicker(this.datePickerOptions);
	},

	initIOSDatePicker: function(minTime) {
		
		var now = new Date(),
			serverTime = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours() + this.serverTimeOffset, now.getUTCMinutes(), now.getUTCSeconds()),
			earliestPickupTime = new Date(serverTime.getFullYear(), serverTime.getMonth(), serverTime.getDate(), serverTime.getHours() + this.serverTimeOffset, serverTime.getMinutes(), serverTime.getSeconds()),
			minTime = now.getUTCHours() + this.serverTimeOffset + this.hoursPadding; // calculating earliest allowed hour (9h ahead of current time)

		var minDateStr = earliestPickupTime.toISOString().replace(/T.*$/, '');

		this.formattedDate = this.formattedDate.replace(/T.*$/, '');
		this.formItem.value = this.formattedDate;
		this.formItem.setAttribute('type', 'date');
		this.formItem.setAttribute('min', minDateStr);
	},

	onClose:function() {

	},

	updatedCalendarHeader:function(selectedDate) {
		Log.db("this.updatedCalendarHeader, selectedDate?",selectedDate);

		var $calendar = $(this),
			$currentDate = $calendar.find("td.xdsoft_current");

		if (!$(".calendar-header").length) {
			// "Ok" button is removed on TaxFree site by request
			//$calendar.append("<button class=\"calendar-save-btn\">OK</button>");
			//$(".calendar-save-btn").on("click", function () {
			//	$calendar.trigger('close.xdsoft');
			//});
		}

		if ($currentDate.length) { // update header value only if value is selected
			var dayNum = parseInt(selectedDate.getDay()) > 0 ? parseInt(selectedDate.getDay()) - 1 : 6; // Sunday is number 6 in array but 0 in Date object
			
			if ( $calendar.find('.calendar-header').length == 0 ) {
				$calendar.find(".xdsoft_mounthpicker").before("<div class='calendar-header'><span class='year'></span> <strong class='day'></strong> <strong class='date'></strong> <strong class='month'></strong></div>");
			}

			// saving localized month names
			var $monthName = $calendar.find(".xdsoft_monthselect .xdsoft_option"),
					monthsList = [];

			$.each($monthName, function (index, item) {
				var monthIndex = $(this).data("value"),
						monthName = $(this).text();

				monthsList[monthIndex] = monthName;
			});

			$calendar.data("monthsList", monthsList);

			// saving localized weekdays
			var $weekDay = $calendar.find(".xdsoft_calendar th"),
					daysList = [];

			$.each($weekDay, function (dayIndex, item) {
				daysList[dayIndex] = $(this).text();
			});

			$calendar.data("daysList", daysList);       
			var weekDays = $calendar.data("daysList"),
				dayName = weekDays[dayNum],
				months = $calendar.data("monthsList"),
				monthNum = parseInt(selectedDate.getMonth()),
				monthName = months[monthNum]; // Shortening month name to 3 characters

			var $calendarHeader = $calendar.find(".calendar-header"),
				$yearHolder = $calendarHeader.find(".year"),
				$monthHolder = $calendarHeader.find(".month"),
				$dayHolder = $calendarHeader.find(".day"),
				$dateHolder = $calendarHeader.find(".date");

			$yearHolder.text(selectedDate.getFullYear());
			$monthHolder.text(monthName.toLowerCase());
			$dayHolder.text(dayName + ".");
			$dateHolder.text(selectedDate.getDate() + ".");
		}
	},

	show:function() {
		Log.fn("DatePickerXDSoft | show",this)
		this.formItem.focus();
	},
	changeHandler:function(event) {	
		this.addClass('is-filled');
		this.eventEmitter.emit('datepicker:change', this.settings.index);
		this.formattedDate = moment(this.formItem.value, this.momentFormat).toDate();

		Log.db("this.formattedDate",this.formItem.value,this.formattedDate)
		$(this.formItem).datetimepicker("option", {defaultDate:this.formattedDate});
		//$(this.formItem).datetimepicker.setOptions({defaultDate:this.formattedDate});
		//this.datePicker.setOptions({defaultDate:this.formattedDate});
	},
	selectHandler:function(event) {		
	},
	onFocus: function() {		
		if (this.hasClass('is-disabled')) return;
		this.formItem.blur();
	},
	onBlur: function() {
		if ( this.formItem.value.length == 0) this.removeClass('is-filled');
	},

	getPicker:function() {
		return this.datePicker;
	}
});


module.exports = DatePickerXDSoft;