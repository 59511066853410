'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var TabPanes = View.extend({

	events: {
		'click .js--primary-tab':'primaryTabClick',
		'click .js--secondary-tab':'secondaryTabClick',
		'change .js--primary-nav select':'primarySelectChange',
		'change .js--secondary-nav select':'secondarySelectChange'
	},

	initialize:function() {
		this.contentPanes = this.find('.js--content');
		this.primaryTabs = this.find('.js--primary-tab');
		this.isMultiLevel = this.el.getAttribute('data-multilevel');
		
		this.secondaryNavs = this.find('.js--secondary-nav');
		this.secondaryTabs = this.find('.js--secondary-tab');
		
		this.activePrimaryTab = this.find('.js--primary-tab.is-active');

		setTimeout(function(){

			if (this.activePrimaryTab instanceof HTMLElement) {
				this.selectPrimary(this.activePrimaryTab, true);
			}
			else {
				this.selectPrimary(this.primaryTabs[0]);				
			}
		}.bind(this), 200);
	},
	
	primaryTabClick: function(event) {
		var tab = event.currentTarget;
		this.selectPrimary(tab);
	},

	selectPrimary: function(tab, overrideAlreadyActive) {
		if ( !(tab instanceof HTMLElement) ) return false;
			
		if ( Utils.hasClass(tab, 'is-active') && !overrideAlreadyActive ) {
			return false;
		} else {
			Utils.each(this.primaryTabs, function(elem) {
				Utils.removeClass(elem,'is-active');
			});

			Utils.each(this.contentPanes, function(elem) {
				Utils.removeClass(elem,'is-active');
				elem.setAttribute('style','');
			});
			Utils.addClass(tab,'is-active');
			
			if ( this.isMultiLevel ) {
				Utils.each(this.secondaryNavs, function(elem) {
					Utils.removeClass(elem,'is-active');
					elem.setAttribute('style','');
				});
				Utils.each(this.secondaryTabs, function(elem) {
					Utils.removeClass(elem,'is-active');
				});

				var secondaryNavId = tab.getAttribute('data-secondarytabid');
				
				if ( secondaryNavId ) {
					var secondaryNav = this.find('.js--secondary-nav[data-secondarytabid="'+secondaryNavId+'"]');
					
					secondaryNav.setAttribute('style','display:block;');
					setTimeout(()=>{
						Utils.addClass(secondaryNav, 'is-active');					
					}, 40);
						
					try {
						var currentSecondaryFirstTab = Utils.find(secondaryNav, '.js--secondary-tab')[0];

						Utils.addClass(currentSecondaryFirstTab, 'is-active');

						var contentId = currentSecondaryFirstTab.getAttribute('data-contentid');
						this.selectContent(contentId);
					}
					catch(error) {
						Log.db("FAILED TO PRESELECT SECONDARY TAB")
					}
				}
			}
			else {
				var contentId = tab.getAttribute('data-contentid');
				this.selectContent(contentId);
			}
		}
	},

	primarySelectChange: function(event) {
		// NOTHING
	},

	secondaryTabClick: function(event) {
		var tab = event.currentTarget;
		if ( Utils.hasClass(tab, 'is-active') ) {
			return false;
		} else {
			var contentId = tab.getAttribute('data-contentid');			
			Utils.each(this.secondaryTabs, function(elem) {
				Utils.removeClass(elem,'is-active');
			});
			Utils.addClass(tab, 'is-active');
			this.selectContent(contentId);
		}
	},

	secondarySelectChange: function(event) {
		var formItem = event.target;		
		
		if ( formItem.selectedIndex ) {
			if ( formItem.options[formItem.selectedIndex] ) {	
				var contentId = formItem.options[formItem.selectedIndex].value;

				this.selectContent(contentId);
			}
		}
	},

	selectContent: function(contentId) {
		if ( contentId ) {
			Utils.each(this.contentPanes, function(elem) {
				Utils.removeClass(elem,'is-active');
				elem.setAttribute('style','');
			}.bind(this));

			var contentPane = this.find('.js--content[data-contentid="'+contentId+'"]');
			contentPane.setAttribute('style','display:block;');

			setTimeout(()=>{
				Utils.addClass(contentPane, 'is-active');
			}, 40);
		}
	}
});

(function(){
	Utils.each(Utils.find('.js--tab-panes'), function(elem) {
		new TabPanes( {el: elem} );
	});
})();

module.exports = TabPanes;