'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var BasketButton = View.extend({
	events: {
		'click': 'clickHandler'
	},
	initialize: function() {
		this.buttonText1 = this.find('.text');
		this.buttonText2 = this.find('.text2');
		this.stayTime = this.el.getAttribute('data-staytime') * 1000;
	},
	clickHandler: function() {
		Utils.removeClass(this.buttonText1, 'is-active');  
		this.addClass('is-active');  

		Utils.addClass(this.buttonText2, 'is-active');  
		setTimeout(function() {
			Utils.addClass(this.buttonText1, 'is-active');
			this.removeClass('is-active');
			Utils.removeClass(this.buttonText2, 'is-active');
		}.bind(this),this.stayTime);
	}
});

(function(){
	Utils.each(Utils.find('.js--button-basket'), function(elem) {
		new BasketButton( {el: elem} );
	});

})();

module.exports = BasketButton;