'use strict';

var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var _ = require('underscore');

var moment = require('moment');

var localeDA = require('../locales/da');
var localeSV = require('../locales/sv');

var Pikaday = require('pikaday');

var DatePickerPikaday = View.extend({
	
	selectedDate:null,

	events: {
		'focus .form__item': 'onFocus',
		'blur .form__item': 'onBlur',
		'change .form__item':'changeHandler'
	},

	datePicker: null,

	initialize: function() {
		Log.fn("DatePickerPikaday | initialize");

		if ( !this.hasClass('is-bound') ) {
			
			// Log.db(">pikaday not yet bound");
			this.currentLocale = localeDA; //TODO: ADD CHECK FOR USER LANG

			moment.defineLocale(this.currentLocale.abbr, this.currentLocale);
			
			this.formItem = this.find('.form__item');
			
			var calendarHeaderTemplateContent = Utils.find('#calendarHeaderTemplate')[0].innerHTML;
			this.headerEl = Utils.createEl('div.pika-lendar__header');
			this.headerEl.innerHTML = calendarHeaderTemplateContent;

			var preFilled = this.hasClass('is-filled');

			var format = 'DD / MM / YYYY',
				preData = {
					value: this.formItem.getAttribute('value'),
					maxdate: this.el.getAttribute('data-maxdate'),
					mindate: this.el.getAttribute('data-mindate')
				};
			
			var data = {};

			if ( !preData.value ) {
				if ( preFilled ) { data.value = new Date(); }
			}
			else { data.value = moment(preData.value, format).toDate(); }

			if ( !preData.mindate ) {
				if ( preFilled ) {
					data.mindate = new Date(); 
					data.mindate.setFullYear((data.mindate.getFullYear()-10)); 
				}
			}
			else { data.mindate = moment(preData.mindate, format).toDate(); }

			if ( !preData.maxdate ) {
				if ( preFilled ) {
					data.maxdate = new Date(); 
					data.maxdate.setFullYear((data.maxdate.getFullYear()+10)); 
				}
			}
			else { data.maxdate = moment(preData.maxdate, format).toDate(); }

			if ( this.settings.nativeMobile && Modernizr.touchevents ) {
				this.formItem.setAttribute('type','date');
			}
			else {
				this.addClass('is-bound');
				this.formItem = this.find('.form__item');
				this.opt = {
					field: this.formItem,
					firstDay: 1,
					format: format,
					showWeekNumber: false,
					onSelect: function(event) {
						this.selectHandler(event);
					}.bind(this),
					onDraw: function() {
						this.drawHandler(event);					
					}.bind(this)
				};
				if ( preFilled ) {
					this.opt.setDefaultDate = true;
					this.opt.defaultDate = data.value;
					this.selectedDate = data.value;
				}
				if ( data.mindate ) this.opt.minDate = data.mindate;
				if ( data.maxdate ) this.opt.maxDate = data.maxdate;

				this.opt.i18n = this.currentLocale;

				this.datePicker = new Pikaday(this.opt);
				this.datePickerEl = this.datePicker.el;			
			}
		} else {
			// Log.db(">pikaday already bound");
		}
	},



	resolveDate: function(val) {
		if(!val) { return }
		return moment().add(val, 'days')._d;
	},
	changeHandler:function(event) {
		this.eventEmitter.emit('datepicker:change', this.settings.index);	
	},
	selectHandler:function(event) {
		// Log.fn("DatePickerPikaday | selectHandler", event, this);
		this.selectedDate = event;		
	},

	show: function() {
		// Log.fn("DatePickerPikaday | show");
		if ( this.datePicker ) this.datePicker.show();
	},

	onFocus: function() {		
		if (this.hasClass('is-disabled')) return;
		this.addClass('has-focus');
		this.addClass('is-filled');		
	},

	drawHandler: function() {
		this.insertCalendarHeader();
	},

	insertCalendarHeader: function() {
		// Log.fn("insertCalendarHeader")
		var pikaLendarEl = Utils.find(this.datePickerEl, '.pika-lendar')[0];
		this.datePickerEl.insertBefore(this.headerEl, pikaLendarEl);

		if ( !this.selectedDate ) this.selectedDate = new Date();
		
		var momentDate = moment(this.selectedDate),
			calendarHeader = Utils.find(this.datePickerEl,'.pika-lendar__header')[0],
			yearHolder = Utils.find(calendarHeader,'.year')[0],
			monthHolder = Utils.find(calendarHeader,'.month')[0],
			dayHolder = Utils.find(calendarHeader,'.day')[0],
			dateHolder = Utils.find(calendarHeader,'.date')[0];

		yearHolder.textContent = momentDate.format('YYYY');
		dayHolder.textContent =  momentDate.format('ddd') + ".";
		dateHolder.textContent = momentDate.format('D') + ".";
		monthHolder.textContent = momentDate.format('MMMM');
	},

	onBlur: function() {		
		this.removeClass('has-focus');
		if ( this.formItem && this.formItem.value.length == 0) this.removeClass('is-filled');
	},
	getPicker:function() {
		return this.datePicker;
	}
});

module.exports = DatePickerPikaday;