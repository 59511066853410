'use strict';
var _ = require('underscore'),
	gsap = require('gsap'),
	SMController = require('scrollmagic').Controller,
	SMScene = require('scrollmagic').Scene;
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var AnimatedReveal = View.extend({

	initialize: function() {
		this.scrollMagicController = new SMController();
		this.scenes = [];

		this.defaultProps = {
			distance: 50
		};

		var animatedElements = Utils.find('.js--animated-reveal');
		
		var wh = window.innerHeight
		|| document.documentElement.clientHeight
		|| document.body.clientHeight;

		[...animatedElements].map((elem)=>{
			Log.fn("AnimatedReveal | element ready", elem)
			var scene = new SMScene({triggerElement: elem, offset:-(wh/3)});
				scene.addTo(this.scrollMagicController);
				scene.on("enter", this.elementInView.bind(this));

			this.scenes.push(scene);
		});
	},

	elementInView: function(event) {
		let element = event.target.triggerElement();
		let dataset = element.dataset;

		Log.fn("AnimatedReveal | elementInView",element);

		if ( !Utils.hasClass(element,'is-animated') ) {
			Utils.addClass(element,'is-animated');
			
			if (dataset.animtype == 'stagger' && dataset.animtarget) {
				let children = Utils.find(element, '.'+dataset.animtarget);
				let tl = new TimelineLite();
				tl.staggerFromTo(children, 0.4, {y:-10,opacity:0},{y:0,opacity:1}, 0.1, "stagger");
			}
			else {
				let toProps = {
					delay:dataset.animdelay ? dataset.animdelay : 0,
					ease:dataset.animease ? window[dataset.animease].easeOut : Power3.easeOut
				};
				let fromProps = {
					delay:dataset.animdelay ? dataset.animdelay : 0,
					ease:dataset.animease ? window[dataset.animease].easeOut : Power3.easeOut
				};

				switch (dataset.animtype){
					case 'up':
						fromProps.opacity = 1;
						toProps.opacity = 1;
						fromProps.y = this.defaultProps.distance;
						break;
					case 'fade':
						toProps.opacity = 1;
						break;
					case 'fadedown':
						toProps.opacity = 1;
						fromProps.y = -this.defaultProps.distance;
						break;
					case 'fadeup':
						toProps.opacity = 1;
						fromProps.y = this.defaultProps.distance;
						break;
					case 'faderight':
						toProps.opacity = 1;
						fromProps.x = this.defaultProps.distance;
						break;
					case 'fadeleft':
						toProps.opacity = 1;
						fromProps.x = -this.defaultProps.distance;
						break;					
					case 'fadescale':
						toProps.opacity = 1;
						fromProps.scale = 0.6;
						break;
				}
				let duration = dataset.animduration ? dataset.animduration : 2;
				
				TweenLite.to(element,duration,toProps);
				TweenLite.from(element,duration,fromProps);
			}
		}

	}
});


(function(){
	new AnimatedReveal({el: window});
})();