'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var Tooltip = View.extend({

	tooltipContent:'',
	
	isActive:false,
	
	tooltipRef:null,
	
	className:'tooltip',

	events: {
		'click': 'toogleTooltip'
	},

	initialize: function(arg){
		this.tooltipContent = this.el.getAttribute('data-tooltip');
		this.bodyRef = Utils.find('body')[0];

		this.addClass('is-bound');
	},

	resizeHandler: function(event) {
		var TO = this.getTO(),
			TW = this.getTW(),
			TH = this.getTH();

		var styles = '';
		if (TO.left+TW > window.innerWidth ){
			styles += 'left: initial;';
			styles += 'top: '+TO.top+'px;';
			styles += 'right: '+ ((TO.left-window.innerWidth) + 180)+'px;';
		}
		else {
			styles += 'left: '+TO.left+'px;';
			styles += 'top: '+TO.top+'px;';
			styles += 'right: initial;';
		}
		this.tooltipRef ? this.tooltipRef.setAttribute('style',styles) : null;
	},

	toogleTooltip: function(event) {
		event.stopPropagation();
		
		if ( !this.isActive ) {
			this.openTooltip();
		}
		else {		
			this.closeTooltip();
		}
	},

	openTooltip: function() {
		this.tooltipRef = Utils.createEl('span'+'.'+this.className);

		this.tooltipRef.innerHTML = this.tooltipContent;

		Utils.append(this.bodyRef, this.tooltipRef);

		this.isActive = true;

		Utils.on(Utils.find('body')[0],'click', this.closeTooltip.bind(this));
		Utils.on(window,'scroll',this.closeTooltip.bind(this) );
		Utils.on(window,'resize',this.resizeHandler.bind(this) );
		
		setTimeout(function(){
			Utils.addClass(this.tooltipRef,'is-active');
			this.resizeHandler();
		}.bind(this), 10);
	},

	closeTooltip: function() {
		if ( this.tooltipRef ) {
			this.isActive = false;
			Utils.off(Utils.find('body')[0],'click', this.closeTooltip.bind(this));
			Utils.off(window,'scroll',this.closeTooltip.bind(this) );
			Utils.off(window,'resize',this.resizeHandler.bind(this) );
			Utils.remove(this.tooltipRef);
			this.tooltipRef = null;
		}
	},

	offset:function( elem ) {

		var docElem, win,
			box = { top: 0, left: 0, bottom:0, right:0 },
			doc = elem && elem.ownerDocument;

		docElem = doc.documentElement;

		if ( typeof elem.getBoundingClientRect !== typeof undefined ) {
			box = elem.getBoundingClientRect();
		}
		win = (doc != null && doc === doc.window) ? doc : doc.nodeType === 9 && doc.defaultView;
		return {
			top: box.top + win.pageYOffset - docElem.clientTop,
			left: box.left + win.pageXOffset - docElem.clientLeft,
			right: box.right + win.pageXOffset - docElem.clientLeft,
			bottom: box.bottom + win.pageYOffset - docElem.clientTop
		};
	},

	getTW:function() {
		return this.tooltipRef ? this.tooltipRef.offsetWidth : 0;
	},
	getTH:function() {
		return this.tooltipRef ? this.tooltipRef.offsetHeight : 0;
	},
	getTO:function() {
		return this.offset(this.el);
	}
});

(function(){
	function init() {
		Utils.each(Utils.find('.js--has-tooltip'), function(elem) {
			if ( !Utils.hasClass(elem, 'is-bound') ) {
				new Tooltip( {el: elem} );
			}
		});
	}
	init();
	window.addEventListener('tooltip:init', init);
})();

module.exports = Tooltip;