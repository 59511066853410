'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var PaymentWithPin = View.extend({
	
	events: {
		'click .js--complete-payment': 'completePaymentHandler',
		'input .js--pin-char': 'pinCharInput',
		'click .js--option': 'optionClickHandler'
	},

	initialize: function(){
		this.pinChars = this.find('.js--pin-char');
		this.options = this.find('.js--option');
		this.pincodeBanner = this.find('.js--pincode-banner');
		this.pincodeBannerAnimDuration = 200;
		
		Log.db("this.options",this.options)
		this.optionRadios = this.find('.js--option-radio');
	},

	completePaymentHandler:function(event) {
		Utils.each(this.pinChars, function(elem) {
			Utils.addClass(elem, 'is-invalid');
		});
		Utils.addClass(this.pincodeBanner,'is-invalid');
	},

	pinCharInput:function(event) {
		var thisIndex = event.target.getAttribute('data-charid');
		var nextIndex = parseInt(thisIndex) + 1 > 4 ? 1 : parseInt(thisIndex) + 1;
		var nextInputId = "#paymentPinChar"+nextIndex;
		var nextInput = Utils.find(this.el, nextInputId)[0];
		
		if ( event.target.value.length == 1 ) {
			this.pinCharsFilled = this.pinCharsFilled == 4 ? 4 : this.pinCharsFilled+1;
			nextInput.focus();
		}
		else { this.pinCharsFilled--; }
	},


	optionClickHandler: function(event) {
		var option = event.currentTarget;

		if ( !Utils.hasClass(option, 'is-disabled') ) {

			Utils.each(this.options, function(elem) {
				Utils.removeClass(elem, 'is-active');
			});
			Utils.addClass(option, 'is-active');
			var optionRadio = Utils.find(option, '.js--option-radio')[0]; 
			
			Log.db("optionRadio",optionRadio)
			try {
				optionRadio.setAttribute('checked','checked');		
				optionRadio.checked = 'checked';				
			}
			catch(err){}
		}

		var hasPin = option.getAttribute('data-haspin') == 'true';

		if ( hasPin ) {
			if ( Utils.hasClass(this.pincodeBanner,'is-disabled') ) {
				Utils.addClass(this.pincodeBanner,'is-showing');				
				Utils.removeClass(this.pincodeBanner,'is-disabled');
				setTimeout(function(){
					Utils.removeClass(this.pincodeBanner, 'is-showing');
				}.bind(this),50);
			}
		}
		else {
			if ( !Utils.hasClass(this.pincodeBanner,'is-disabled') ) {
				Utils.addClass(this.pincodeBanner,'is-hiding');					
				setTimeout(function(){
					Utils.removeClass(this.pincodeBanner, 'is-hiding');
					Utils.addClass(this.pincodeBanner,'is-disabled');
				}.bind(this),this.pincodeBannerAnimDuration);
			}
		}
	}
});

(function(){
	Utils.each(Utils.find('.js--payment-with-pin'), function(elem) {
		new PaymentWithPin( {el: elem} );
	});

})();

module.exports = PaymentWithPin;

