'use strict';
var _ = require('underscore'),
	gsap = require('gsap');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var CategoryBrowser = View.extend({

	events: {
		'click .js--scroll-left': 'onScrollLeftButton',
		'click .js--scroll-right': 'onScrollRightButton',
		'onscroll .js--category-browser-list': 'onScrollList'
	},

	initialize: function(){
		this.htmlRef = Utils.find('html')[0];
		this.categoryBrowserList = this.find('.js--category-browser-list');
		this.scrollLeftButton = this.find('.js--scroll-left');
		this.scrollRightButton = this.find('.js--scroll-right');

		this.onScrollList();

		this.logoButton = this.find('.button--logo');
		this.miniBasketButton = this.find('.button--nav--basket');

		this.inner = this.find('.js--sticky-menu-inner');

		this.lastScrolled = 0;
		this.scrolledDown = 0;
		this.scrolledUp = 0;
		this.scrollTrigger = 150;

		Utils.on(window, 'scroll', this.siteScrollHandler.bind(this) );

		this.siteScrollHandler();
	},

	siteScrollHandler: function() {
		var newScrollPos = (document.documentElement.scrollTop||document.body.scrollTop);

		// If sticky menu is X from top
		if (newScrollPos < 200) {
			this.addClass('is-home');
			this.removeClass('is-sticky');
			this.isSticky = false;
			Utils.removeClass(this.htmlRef, 'is-sticky-mode');
			this.removeClass('is-ready');
		} else {
			this.removeClass('is-home');
			
			// Scroll direction (DOWN)
			if (newScrollPos > this.lastScrolled) {
				this.scrolledDown += newScrollPos - this.lastScrolled;
				this.addClass('is-sticky');
				this.isSticky = true;
				Utils.addClass(this.htmlRef, 'is-sticky-mode');
				this.readyTimeoutHandle = setTimeout(function(){
					this.addClass('is-ready');

				}.bind(this), 200);
				// if (this.scrolledDown > this.scrollTrigger) {
				// 	this.removeClass('is-sticky');
				// }

				this.scrolledUp = 0;			
			}
		}

		this.lastScrolled = (document.documentElement.scrollTop||document.body.scrollTop);
	},

	onScrollList: function(event) {
		//Log.fn("onScrollList")
		var scrollLeft = this.categoryBrowserList.scrollLeft,
			scrollWidth = this.categoryBrowserList.scrollWidth,
			wWidth = window.innerWidth;

		if ( scrollLeft <= 30 ) {
			Utils.addClass(this.scrollLeftButton, 'is-inactive');
		}
		else {
			Utils.removeClass(this.scrollLeftButton, 'is-inactive');
		}
		
		var extraWidth = this.isSticky ? this.logoButton.offsetWidth + this.miniBasketButton.offsetWidth : 0;
		if ( (scrollLeft + wWidth - extraWidth) >= scrollWidth ) {
			Utils.addClass(this.scrollRightButton, 'is-inactive');
		}
		else {
			Utils.removeClass(this.scrollRightButton, 'is-inactive');
		}		
		window.requestAnimationFrame( this.onScrollList.bind(this) );
	},

	onScrollLeftButton: function(event) {
		//Log.fn("onScrollLeftButton")
		var scrollLeft = this.categoryBrowserList.scrollLeft,
			scrollWidth = this.categoryBrowserList.scrollWidth,
			wWidth = window.innerWidth,
			scrollIncrement = this.getScrollIncrement(wWidth),
			newScrollLeft = scrollLeft-scrollIncrement < scrollIncrement ? 0 : scrollLeft-scrollIncrement;

		TweenLite.to(this.categoryBrowserList, 0.5, {scrollLeft:newScrollLeft, ease: Expo.easeOut});
	},

	onScrollRightButton: function(event) {
		//Log.fn("onScrollRightButton")
		var extraWidth = this.isSticky ? this.logoButton.offsetWidth + this.miniBasketButton.offsetWidth : 0;
		var scrollLeft = this.categoryBrowserList.scrollLeft,
			scrollWidth = this.categoryBrowserList.scrollWidth,
			wWidth = window.innerWidth,
			maxScrollLeft = (scrollLeft + wWidth - extraWidth),
			scrollIncrement = this.getScrollIncrement(wWidth),
			newScrollLeft = scrollLeft+scrollIncrement > maxScrollLeft-scrollIncrement ? maxScrollLeft : scrollLeft+scrollIncrement;
		
		TweenLite.to(this.categoryBrowserList, 0.5, {scrollLeft:newScrollLeft, ease: Expo.easeOut});
	},

	getScrollIncrement(wWidth){
		if ( wWidth < 768 ) {

			return this.isSticky ? 90 : 180;
		}
		return this.isSticky ? 180 : 360;
	}

});

(function(){		
	Utils.each(Utils.find('.js--category-browser'), function(elem) {
		new CategoryBrowser( {el: elem} );
	});
})();

module.exports = CategoryBrowser;