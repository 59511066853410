'use strict';
var _ = require('underscore'),
	gsap = require('gsap');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var StylishTable = View.extend({

	initialize: function(){
		this.lastScrolled = 0;
		this.scrolledDown = 0;
		this.scrolledUp = 0;
		

		Utils.on(window, 'scroll', this.siteScrollHandler.bind(this) );
		Utils.on(window, 'resize', this.siteResizeHandler.bind(this) );

		this.siteScrollHandler();
		this.siteResizeHandler();
	},

	siteScrollHandler: function() {
		var newScrollPos = (document.documentElement.scrollTop||document.body.scrollTop);

		if (newScrollPos < 200 || newScrollPos <= this.scrollTrigger) {
			this.removeClass('is-sticky');
		} else {
			if (newScrollPos > this.lastScrolled && newScrollPos > this.scrollTrigger ) {
				this.scrolledDown += newScrollPos - this.lastScrolled;
				this.addClass('is-sticky');				
				this.scrolledUp = 0;			
			}
		}
		this.lastScrolled = (document.documentElement.scrollTop||document.body.scrollTop);
	},

	siteResizeHandler: function(event) {
		this.scrollTrigger = this.getCoords(this.el).top;
	},

	getCoords: function(elem) { // crossbrowser version
		var box = elem.getBoundingClientRect();

		var body = document.body;
		var docEl = document.documentElement;

		var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
		var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

		var clientTop = docEl.clientTop || body.clientTop || 0;
		var clientLeft = docEl.clientLeft || body.clientLeft || 0;

		var top  = box.top +  scrollTop - clientTop;
		var left = box.left + scrollLeft - clientLeft;

		return { top: Math.round(top), left: Math.round(left) };
	}

});

(function(){		
	Utils.each(Utils.find('.js--stylish-table'), function(elem) {
		new StylishTable( {el: elem} );
	});
})();

module.exports = StylishTable;