'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils,
	Forms = require('../components/forms');
	
var ModalFlow = View.extend({
	
	currentStep:null,
	
	currentStepId:0,
	
	events: {
		'transitionend': 'onTransitionEnd',
		'otransitionend': 'onTransitionEnd',
		'webkitTransitionEnd': 'onTransitionEnd',
		'click .button': 'nextStep'
	},

	initialize:function() {
		setTimeout(function(){
			Utils.each( Utils.find(this.el, '.form'), function(elem) {
				new Forms({el: elem});
			});
		}.bind(this),3000)

		this.nextStep();
	},
	nextStep: function(event) {
		if ( this.currentStep ) {
			Utils.removeClass(this.currentStep, 'is-active');
			Utils.removeClass(this.currentStep, 'is-animating');
		}
		
		this.currentStepId++;
		this.currentStep = this.find('[data-stepid="'+this.currentStepId+'"]');
		
		if ( this.currentStep instanceof HTMLElement ) {
			Utils.addClass(this.currentStep, 'is-animating');
			setTimeout( function(){ Utils.addClass(this.currentStep, 'is-active'); }.bind(this), 50);
		}
		else {
			this.eventEmitter.emit('modal:close');	
			this.eventEmitter.emit('overlay:clicked');	
		}
	},

	onTransitionEnd:function(event) {
		//Log.fn('ModalFlow | onTransitionEnd');
	}
});

(function(){
	Utils.each(Utils.find('.js--modal-flow'), function(elem) {
		new ModalFlow( {el: elem} );
	});

})();

module.exports = ModalFlow;