'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var SearchAheadPosition = {LEFT:0,RIGHT:1};

var SearchAhead = View.extend({
	
 	searchAheadSize: 1.5,
 	searchAheadDelay: 500,
 	appendSearchAhead: true,

	events: {
		'keyup input': 'keyHandler'
		,'focus input': 'focusHandler'
		,'blur input': 'blurHandler'
	},
	
	initialize: function() {
		this.searchAheadRef = Utils.find('.search-ahead')[0];

		if ( this.searchAheadRef instanceof HTMLElement ) {			
			this.position = {};
			this.inputRef = this.find('input');
			this.bodyRef = Utils.find('body')[0];
			this.searchAheadClass = this.el.getAttribute('data-search-ahead-class') ? this.el.getAttribute('data-search-ahead-class') : null;
			this.timeoutHandle = -1;
			this.searchAheadActive = false;

			Utils.on(window, 'resize', this.resizeHandler.bind(this));
			this.resizeHandler();
		}
		else {
			//throw new Error("No element with 'search-ahead' class found in page.");
		}
	},

	keyHandler: function() {

		clearTimeout(this.timeoutHandle);
		this.timeoutHandle = setTimeout(function(){
			this.showSearchAhead();
		}.bind(this), this.searchAheadDelay);
	},


	
	showSearchAhead: function() {
		if ( !this.searchAheadActive ) {

			this.searchAheadActive = true;
			this.inputRef.setAttribute('style','position:relative;z-index:6;')

			this.resizeHandler();

			this.inputRef.parentNode.appendChild(this.searchAheadRef);

			Utils.addClass(this.searchAheadRef,'is-visible');
			clearTimeout(this.timeoutHandle);
			
			this.timeoutHandle = setTimeout(function(){
				Utils.addClass(this.searchAheadRef,'is-active');
				Utils.addClass(this.inputRef,'has-suggestions');
				if ( this.searchAheadClass ) Utils.addClass(this.searchAheadRef,this.searchAheadClass);
				Utils.addClass(this.inputRef.parentNode,'has-input-with-suggestions');
			}.bind(this), 50);

		}
	},
	
	hideSearchAhead: function() {
		this.searchAheadActive = false;
		clearTimeout(this.timeoutHandle);
		this.inputRef.setAttribute('style','')
		Utils.removeClass(this.inputRef.parentNode,'has-input-with-suggestions');
		Utils.removeClass(this.inputRef,'has-suggestions');
		
		Utils.removeClass(this.searchAheadRef,'is-active');
		this.timeoutHandle = setTimeout(function(){
			Utils.removeClass(this.searchAheadRef,'is-visible');
		}.bind(this), 300);
	},

	focusHandler: function() {
		clearTimeout(this.blurTimeout);

		this.inputRef.value = '';
		this.searchAheadActive = false;
		this.hideSearchAhead();
		
		var activeElement = document.activeElement;
	},
	
	blurHandler: function() {
		this.blurTimeout = setTimeout(function(){			
			var activeElement = document.activeElement;
			var inputs = ['input', 'select', 'button', 'textarea'];
			
			if ( activeElement && inputs.indexOf(activeElement.tagName.toLowerCase() !== -1)) {
				Log.db("SHUTTING THE SUCKER DOWN!")
				this.searchAheadActive = false;
				this.hideSearchAhead();
			}
		}.bind(this),500);
	},

	resizeHandler:function() {
		var windowWidth = window.innerWidth,
			inputWidth = this.inputRef.offsetWidth,
			offset = this.offset(this.inputRef),
			styles = '';

		var searchAheadWidth = inputWidth;
		Utils.removeClass(this.searchAheadRef,'search-ahead--products');
		Utils.removeClass(this.searchAheadRef,'search-ahead--left');
		Utils.removeClass(this.searchAheadRef,'search-ahead--right');
		Utils.removeClass(this.searchAheadRef,'search-ahead--full');

		if ( (windowWidth < 950 && inputWidth == windowWidth) ) {
			if ( windowWidth < 768 ) { 
				searchAheadWidth = windowWidth;
			}
			else {
				searchAheadWidth = searchAheadWidth < this.searchAheadMinWidth ? this.searchAheadMinWidth : searchAheadWidth;
			}
			styles = 'left:0;right:0;';
			Utils.addClass(this.searchAheadRef,'search-ahead--full');			
		}
		else if ( (offset.left == windowWidth-offset.right) ) {
			styles = 'left:1px;right:1px;';			
		}
		else {
			searchAheadWidth = this.searchAheadSize * inputWidth;
			var inputLocation = (offset.left < windowWidth-offset.right) ? SearchAheadPosition.LEFT : SearchAheadPosition.RIGHT;
			
			switch (inputLocation) {
				case SearchAheadPosition.LEFT:
					if ( searchAheadWidth > windowWidth - 2 * offset.left ) searchAheadWidth = windowWidth - 2 * offset.left;
					styles = 'width:'+searchAheadWidth+'px;left:1px;';
					Utils.addClass(this.searchAheadRef,'search-ahead--left');
					break;
				case SearchAheadPosition.RIGHT:
					if ( searchAheadWidth > windowWidth - 2 * (windowWidth-offset.right) ) searchAheadWidth = windowWidth - 2 * (windowWidth-offset.right);					
					styles = 'width:'+searchAheadWidth+'px;right:1px;';
					Utils.addClass(this.searchAheadRef,'search-ahead--right');					
					break;
			}
		}

		this.searchAheadRef.setAttribute('style', styles);
	},

	offset:function( elem ) {

		var docElem, win,
			box = { top: 0, left: 0, bottom:0, right:0 },
			doc = elem && elem.ownerDocument;

		docElem = doc.documentElement;

		if ( typeof elem.getBoundingClientRect !== typeof undefined ) {
			box = elem.getBoundingClientRect();
		}
		win = (doc != null && doc === doc.window) ? doc : doc.nodeType === 9 && doc.defaultView;
		return {
			top: box.top + win.pageYOffset - docElem.clientTop,
			left: box.left + win.pageXOffset - docElem.clientLeft,
			right: box.right + win.pageXOffset - docElem.clientLeft,
			bottom: box.bottom + win.pageYOffset - docElem.clientTop
		};
	},


});

(function(){
	Utils.each(Utils.find('.js--has-search-ahead'), function(elem) {
		new SearchAhead( {el: elem} );
	});
})();

module.exports = SearchAhead;