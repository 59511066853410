'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils,
	Forms = require('../components/forms');

var AccountModal = View.extend({

	events: {
		'click .js--toggle-mode':'toggleMode'
	},
	initialize:function() {
		this.modeButtons = this.find('.js--toggle-mode');
		this.modes = this.find('.js--mode');

		Utils.each(this.modes, function(elem) {
			var modeId = elem.getAttribute('data-modeid');

			setTimeout(function(){
				if ( modeId != 0 ) {
					Utils.removeClass(elem,'is-active');
					elem.setAttribute('style','');
				}
				else {
					elem.setAttribute('style','display:block;');
					Utils.addClass(elem, 'is-active');
				}
			}.bind(this), 250);
		});

		Utils.each( Utils.find(this.el, '.form'), function(elem) {
			new Forms({el: elem});
		});
	},
	toggleMode: function(event) {
		Utils.each(this.modeButtons, function(elem) {
			Utils.removeClass(elem,'is-active');
		});
		Utils.each(this.modes, function(elem) {
			Utils.removeClass(elem,'is-active');
			elem.setAttribute('style','');
		});
		var button = event.currentTarget;
		if ( Utils.hasClass(button,'is-disabled') ) return false;
		Log.db("button",button)
		
		Utils.addClass(button,'is-active');

		var modeId = button.getAttribute('data-modeid');
		Log.db("modeId",modeId)
		var mode = this.find('.js--mode[data-modeid="'+modeId+'"]');

		mode.setAttribute('style','display:block;');
		setTimeout(function(){Utils.addClass(mode, 'is-active');}.bind(this), 50);
	},
});

(function(){
	Utils.each(Utils.find('.js--account-modal'), function(elem) {
		new AccountModal( {el: elem} );
	});

})();

module.exports = AccountModal;