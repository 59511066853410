"use strict";
var	_ = require('underscore');

var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;


var Accordion = View.extend({
	
	events: {
		"click .button": "handleInlineButtonClick"
	},
	
	resizeTimeout:0,

	savedWindowWidth:0,

	maxHeightClosed: 0,
	maxHeightOpen: 0,

	initialize: function() {
		this.trigger = Utils.find(this.el, '.js--accordion-trigger')[0];
		this.content = Utils.find(this.el, '.js--accordion-content')[0];
		
		if ( this.settings.persistentElement ) {
			this.persistentElement = this.find(this.settings.persistentElement);
			if ( !(this.persistentElement instanceof HTMLElement) ) this.persistentElement = null;
		}

		this.active = this.hasClass('is-active');
		this.updateHeight();

		this.savedWindowWidth = window.innerWidth;
		Utils.on(window, 'resize', this.resizeHandler.bind(this) );

		this.eventEmitter.on('accordion:updateheight', this.updateHeight.bind(this) );		
		window.addEventListener('accordion:updateheight', this.updateHeight.bind(this) );		

		Utils.on(this.trigger, this.settings.eventName, this.onToggle.bind(this) );

		this.addClass('is-bound');

	},

	updateHeight:function() {
		this.content.style.maxHeight = 'none';

		if ( this.active ) {
			this.maxHeightOpen = this.getHeight(this.content);
			if ( this.persistentElement ) this.maxHeightClosed = this.getHeight( this.persistentElement ) - 1; //JPL: To compensate for possible border		
		}
		else {
			setTimeout(()=>{
				if ( this.persistentElement ) this.maxHeightClosed = this.getHeight( this.persistentElement ) - 1; //JPL: To compensate for possible border		
				this.maxHeightOpen = this.getHeight(this.content);
				this.content.style.maxHeight = this.maxHeightClosed+'px';
			},20);
		}
	},



	onToggle: function(e){
		e.preventDefault();
		e.stopPropagation();

		this.active = !this.active;
		this.content.style.maxHeight = this.active ? this.maxHeightOpen+'px' : this.maxHeightClosed+'px';
		this.toggleClass('is-active', this.active);

		Utils.toggleClass(this.trigger,'is-active');

		if ( this.settings.disposable ) {
			Utils.remove(this.trigger);
		}
	},

	handleInlineButtonClick: function(e){
		e.stopPropagation();
	},

	resizeHandler:function() {
		var width = window.outerWidth;
		if ( this.savedWindowWidth === width ) return false;
		else {
			this.savedWindowWidth = width;

			if ( this.active ) this.content.style.maxHeight = 'none'; //JPL: While resizing, show all if open

			clearTimeout(this.resizeTimeout);
			this.resizeTimeout = setTimeout(function(){


				this.updateHeight();
			}.bind(this),500);
		}
	},
	


	/**
	* getHeight - for elements with display:none
	*/
	getHeight: function(el) {
		var el_style      = window.getComputedStyle(el),
			el_display    = el_style.display,
			el_position   = el_style.position,
			el_visibility = el_style.visibility,
			el_max_height = el_style.maxHeight.replace('px', '').replace('%', ''),

			wanted_height = 0;


		// if its not hidden we just return normal height
		if(el_display !== 'none' && el_max_height !== '0') {
			return el.offsetHeight;
		}

		// the element is hidden so:
		// making the el block so we can meassure its height but still be hidden
		el.style.position   = 'absolute';
		el.style.visibility = 'hidden';
		el.style.display    = 'block';

		wanted_height     = el.offsetHeight;

		// reverting to the original values
		el.style.display    = el_display;
		el.style.position   = el_position;
		el.style.visibility = el_visibility;

		return wanted_height;
	}
});

Accordion.EVENT = {CLICK:'click',CHANGE:'change'};

(function(){
	function init() {
		Utils.each(Utils.find(".js--accordion"), function(elem){
			if ( !Utils.hasClass(elem, 'is-bound') ) {
				var data = elem.dataset,
					eventName = data.eventname ? data.eventname : Accordion.EVENT.CLICK,
					disposable = data.disposable ? true : false,
					persistentElement = data.persistentelement ? data.persistentelement : null;
				
				new Accordion({el: elem, eventName:eventName, disposable:disposable, persistentElement: persistentElement});
			}
		});
	}
	init();
	window.addEventListener('accordion:init', init);
})();


module.exports = Accordion;
