'use strict';
var _ = require('underscore'),
	Swiper = require('swiper');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var ProductSlider = View.extend({
	
	initialize: function() {
		
		var dataset = this.el.dataset;

		var layout = dataset.layout,
			loop = dataset.loop ? dataset.loop : false,
			gutter = !isNaN(dataset.gutter) ? parseInt(dataset.gutter) : 20;
			
			// Log.db("layout="+layout+", loop="+loop+", gutter="+gutter);

		var chosenLayout;

		if ( layout == "isolated" ) {
			chosenLayout = ProductSlider.LAYOUT.ISOLATED;
		}
		else if ( layout == "confined" ) {
			chosenLayout = ProductSlider.LAYOUT.CONFINED;
		}
		else {
			chosenLayout = ProductSlider.LAYOUT.DEFAULT
		}
		// Log.db("chosenLayout",chosenLayout);
		
		var element = this.find('.product-list__inner'),
			nextButton = this.find('.js--slide-right'),
			prevButton = this.find('.js--slide-left');

		var slider = new Swiper(
			element,
			{
				wrapperClass: 'product-list__slider',
				nextButton: nextButton,
				prevButton: prevButton,
				buttonDisabledClass: 'is-disabled',
				slideClass: 'product-list__item',
				slidesPerView: chosenLayout.slidesPerView,
				slidesPerGroup: chosenLayout.slidesPerGroup,
				spaceBetween: gutter,
				simulateTouch : true,
				roundLengths: true,
				breakpoints: chosenLayout.breakpoints,
				loop: loop,
				slidesPerView: 'auto'
			}
		);

		this.addClass('is-bound');
	}
});

ProductSlider.LAYOUT = {
	DEFAULT: { //JPL: Used for product-sliders taking up left side of contentbanner
		name: "default",
		slidesPerView: 6,
		slidesPerGroup: 2,
		breakpoints: {
			2600: {
				slidesPerView: 6,
				slidesPerGroup: 2
			},
			2249: {
		   		slidesPerView: 5,
				slidesPerGroup: 2
			},
		   	1919: {
				slidesPerView: 4,
				slidesPerGroup: 2
			},
			1679: {
				slidesPerView: 3,
				slidesPerGroup: 2
			},
			1439: {
				slidesPerView: 2,
				slidesPerGroup: 1
			},
			949: {
				slidesPerView: 3,
				slidesPerGroup: 2
			},
			669: {
				slidesPerView: 2,
				slidesPerGroup: 1
			},
			479: {
				slidesPerView: 1,
				slidesPerGroup: 1
			}
		}
	},
	ISOLATED: {  //JPL: Used for product-sliders taking up full screen width
		name: "isolated",
		slidesPerView: 8,
		slidesPerGroup: 2,
		breakpoints: {
			2600: {
				slidesPerView: 8,
				slidesPerGroup: 2,
			},
		   	2249: {
				slidesPerView: 7,
				slidesPerGroup: 2
			},
			1919: {
				slidesPerView: 6,
				slidesPerGroup: 2
			},
			1679: {
				slidesPerView: 5,
				slidesPerGroup: 2
			},
			1439: {
				slidesPerView: 4,
				slidesPerGroup: 2
			},
			949: {
				slidesPerView: 3,
				slidesPerGroup: 2
			},
			669: {
				slidesPerView: 2,
				slidesPerGroup: 1
			},
			479: {
				slidesPerView: 1,
				slidesPerGroup: 1
			}
		}
	},
	CONFINED: { //JPL: Used for product-sliders confined within page width w-- modifier-class
		name: "confined",
		slidesPerView: 4,
		slidesPerGroup: 2,
		breakpoints: {
			2600: {
				slidesPerView: 4,
				slidesPerGroup: 2,
			},
		   	2249: {
				slidesPerView: 4,
				slidesPerGroup: 2
			},
			1919: {
				slidesPerView: 4,
				slidesPerGroup: 2
			},
			1679: {
				slidesPerView: 4,
				slidesPerGroup: 2
			},
			1439: {	
				slidesPerView: 4,
				slidesPerGroup: 1
			},
			949: {
				slidesPerView: 3,
				slidesPerGroup: 1
			},
			669: {
				slidesPerView: 2,
				slidesPerGroup: 1
			},
			479: {
				slidesPerView: 1,
				slidesPerGroup: 1
			}
		}
	}
};



(function(){
	function init() {
		Utils.each(Utils.find('.js--product-slider'), function(elem) {
			if ( !Utils.hasClass(elem, 'is-bound') ) {				
				new ProductSlider( {el: elem} );
			}
		});
	}
	init();
	window.addEventListener('productslider:init', init);	
})();



module.exports = ProductSlider;