'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var CookieCompliance = View.extend({

	initialize:function() {
		Utils.on(Utils.find('body')[0],'click', this.accept.bind(this));
	},
	accept: function() {
		this.remove();
	}
});

(function(){
	Utils.each(Utils.find('.js--cookie-compliance'), function(elem) {
		new CookieCompliance( {el: elem} );
	});

})();

module.exports = CookieCompliance;