'use strict';
var _ = require('underscore'),
	Quantity = require('../components/quantity'),
	Swiper = require('swiper');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var ProductDetails = View.extend({

	savedWindowWidth:0,

	events: {
		'click .js--image-thumb' : 'imageThumbHandler'
	},

	initialize:function() {		
		var quantityEl = this.find('.js--quantity');
		if ( quantityEl instanceof HTMLElement ) {
			this.quantity = new Quantity( {el: quantityEl} );
		}

		this.setupSlider();
		this.setupImageSwapper();
	},

	setupImageSwapper: function() {

		this.fullImage = this.find('.js--image-full');
		this.imageThumbs = this.find('.js--image-thumb');
		this.imageDesktop = this.find('.js--image-main');
		
		Utils.on(window, 'resize', this.resizeHandler.bind(this) );
		this.resizeHandler();
	},


	resizeHandler:function() {
		var width = window.outerWidth;
		Log.db("width",width)
		Log.db("savedWindowWidth",this.savedWindowWidth)
		if ( this.savedWindowWidth === width ) return false;
		else {
			this.savedWindowWidth = width;
			var imageDesktopHeight = this.imageDesktop.offsetHeight + 20;				
			if ( width > 950 ) { this.el.setAttribute('style', 'min-height: '+imageDesktopHeight+'px;'); }
			else { this.el.setAttribute('style', ''); }
		}
	},


	imageThumbHandler: function(event) {
		var thumb = event.currentTarget instanceof HTMLElement ? event.currentTarget : null;

		if ( Utils.hasClass(thumb, 'is-active') ) return false;
			
		var newSrc = thumb ? thumb.getAttribute('data-src') : '';
		
		if (newSrc.length) {
			Utils.addClass(this.fullImage,'is-loading');
			setTimeout(function(){
				this.fullImage.setAttribute('src',newSrc);				
				Utils.removeClass(this.fullImage,'is-loading');
			}.bind(this), 200);
			

			Utils.each(this.imageThumbs, function(elem){
				Utils.removeClass(elem,'is-active');
			}.bind(this));

			Utils.addClass(thumb,'is-active');
		}
	},

	setupSlider: function() {
		var slider = this.find('.js--slider'),
			pagination =  this.find('.js--pagination');

		Log.db("slider",slider)
		Log.db("pagination",pagination)

		var swiper = new Swiper(slider, {
			speed: 650,
			wrapperClass: 'product-details__image__slider__container',
			slideClass: 'product-details__image__slider__item',
			slidesPerView: 1,
			centeredSlides: true,
			loop: true,
			simulateTouch : true,
			roundLengths: true,
			paginationClickable: true,
			pagination: pagination
		});
	}
	
});

(function(){
	Utils.each(Utils.find('.js--product-details'), function(elem) {
		new ProductDetails( {el: elem} );
	});

})();

module.exports = ProductDetails;