'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var VideoPlayer = View.extend({
	events: {
		'click .button--play':'playButtonHandler'
	},
	initialize:function() {
		if ( !YT ) {
			Log.er('NO YT FOUND');
			return false;
		}
		
		this.video = this.find('.player');
		this.playerId = this.video.getAttribute('id');
		this.videoId = this.video.getAttribute('data-youtubeid');
		this.button = this.find('.button--play');

		this.player = new YT.Player(this.playerId, {
			height: '100%',
			width: '100%',
			videoId: this.videoId,
			events: {
				'onReady': this.onPlayerReady.bind(this),
				'onStateChange': this.onPlayerStateChange.bind(this)
			}

		});
		var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
		if ( iOS ) Utils.removeClass(this.button, 'is-active');
	},
	onPlayerReady:function(event) {
		this.playerReady = true;
		this.addClass('is-ready');
	},
	onPlayerStateChange:function(event) {
		if ( event.data == YT.PlayerState.ENDED ) {
			Utils.addClass(this.button, 'is-active');
		}
	},
	playButtonHandler: function() {
		if ( this.playerReady && this.player && this.player.playVideo ) {
			this.player.playVideo();
			Utils.removeClass(this.button, 'is-active');
		}
	},
	stopVideo: function() {
	}
});

(function(){

	var tag = document.createElement('script');

	tag.src = "https://www.youtube.com/iframe_api";
	var firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	
	window.onYouTubeIframeAPIReady = function() {
		Utils.each(Utils.find('.js--video-player'), function(elem) {
			new VideoPlayer( {el: elem} );
		});
	}

})();

module.exports = VideoPlayer;