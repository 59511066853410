'use strict';
var _ = require('underscore'),
	moment = require('moment'),
	Pikaday = require('pikaday');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;


var Overlay = View.extend({
	viewName:'Overlay',
	className: 'overlay overlay--modal',

	events: {
		'click': 'onClick'
	},

	initialize: function(){
		this.bodyRef = Utils.find('body')[0];
		if ( this.settings.fixBody ) Utils.addClass(this.bodyRef,'is-fixed');
		if ( this.settings.isBlurred ) Utils.addClass(this.bodyRef,'is-blurred');
		if ( this.settings.isFixed ) this.addClass('overlay--fixed');
		if ( this.settings.whiteBg ) this.addClass('overlay--white');

		this.eventEmitter.on('modal:close', this.close.bind(this));
		
	},
	render: function() {
		Utils.append(this.bodyRef, this.el);
		
		setTimeout(function(){
			this.addClass('is-active');
		}.bind(this), 1);
	},

	onClick: function(e){
		if(e.target != this.el) { return false; }

		e.stopPropagation();
		e.preventDefault();
		this.eventEmitter.emit('overlay:clicked', e);
		this.close();
	},

	close:function(e) {
		Utils.removeClass(this.bodyRef,'is-fixed');
		Utils.removeClass(this.bodyRef,'is-blurred');
		this.remove();
	}
});

module.exports = Overlay;
