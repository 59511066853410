'use strict';
var _ = require('underscore'),
	moment = require('moment'),
	Pikaday = require('pikaday');

var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var SearchBar = View.extend({

	isActive: false,
	searchString: '',

	events: {
		//'click .js--search-field-toggle': 'onToggle'
	},
	initialize: function(){
		this.searchField = this.find('.search__field');
		this.searchFieldInput = this.find('input[type=text]');
		this.eventEmitter.on('menu:open', this.forceClose.bind(this) );
		Utils.on(Utils.find('body')[0],'click', this.bodyClick.bind(this));
	},
	onToggle: function() {
		if ( !this.isActive ) {
			this.open();
		}
		else {
			var value = this.searchFieldInput.value;
			if ( value.length > 0 ) {
				window.location.href = window.location.href + '?q=' + value;
			}
			else {
				this.forceClose("");
			}
		}

	},
	open:function() {
		Utils.addClass(this.searchField,'is-active');
		this.isActive = true;
		this.searchFieldInput.focus();
		this.eventEmitter.emit('menu:open', this.instanceId);
	},
	forceClose:function(instanceId){
		if ( typeof instanceId == "undefined") {}
		else if ( instanceId == this.instanceId ) return false;

		//Utils.off(Utils.find('body')[0], 'click', this.bodyClick.bind(this) );
		if ( this.searchField instanceof HTMLElement ) {
			Utils.removeClass(this.searchField,'is-active');			
			this.isActive = false;
		}
	},

	bodyClick:function(event) {
		var search = Utils.closestByClass(event.target,'js--search-bar');			
		var searchButton = Utils.closestByClass(event.target,'js--search-field-toggle');
		if ( search ) {
			if ( searchButton ) this.onToggle();
		}
		else {
			this.forceClose();
		}
	}
});
(function(){
	Utils.each(Utils.find('.js--search-bar'), function(elem) {
		new SearchBar( {el: elem} );
	});

})();

module.exports = SearchBar;