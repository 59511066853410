'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var ParkingLotPicker = View.extend({
	events: {
		'click .js--parking-lot-toggle-list': 'onToggleList',
		'click .js--parking-lot-toggle-map': 'onToggleMap'
	},
	initialize: function(){
		this.lotList = this.find('.js--parking-lot-list');
		this.lotListToggle = this.find('.js--parking-lot-toggle-list');
		this.lotMap = this.find('.js--parking-lot-map');
		this.lotMapToggle = this.find('.js--parking-lot-toggle-map');
	},
	onToggleList: function() {
		Utils.removeClass(this.lotMap,'is-active');
		Utils.removeClass(this.lotMap,'is-animating');
		Utils.removeClass(this.lotMapToggle,'is-active');
		Utils.addClass(this.lotList,'is-animating');
		setTimeout(function(){
			Utils.addClass(this.lotList,'is-active');
		}.bind(this),30);
		Utils.addClass(this.lotListToggle,'is-active');
		this.eventEmitter.emit('map:deactivate');
	},
	onToggleMap: function() {
		Utils.removeClass(this.lotList,'is-active');
		Utils.removeClass(this.lotList,'is-animating');
		Utils.removeClass(this.lotListToggle,'is-active');
		Utils.addClass(this.lotMap,'is-animating');
		setTimeout(function(){
			Utils.addClass(this.lotMap,'is-active');
		}.bind(this),30);
		Utils.addClass(this.lotMapToggle,'is-active');
		this.eventEmitter.emit('map:activate');
	}
});

(function(){
	Utils.each(Utils.find('.js--parking-lot-picker'), function(elem) {
		new ParkingLotPicker( {el: elem} );
	});

})();

module.exports = ParkingLotPicker;