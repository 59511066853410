'use strict';
var _ = require('underscore'),
	moment = require('moment'),
	Pikaday = require('pikaday'),
	Choices = require('choices.js'),
	DatePickerXDSoft = require('../components/datepicker-xdsoft'),
	DatePickerPikaday = require('../components/datepicker-pikaday');

var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var ParkingBooking = View.extend({
	events: {
		'click .js--book': 'testTimePicker'
	},
	initialize: function(){
		var dateTimePairElems = Utils.find('.js--date-time-pair');
		
		this.dateTimePair1 = new ParkingDateTimePair({el:dateTimePairElems[0], index:0});
		this.dateTimePair2 = new ParkingDateTimePair({el:dateTimePairElems[1], index:1});

		this.dateTimePair1.linkWith(this.dateTimePair2);
		//this.dateTimePair2.linkWith(this.dateTimePair1);
	},
	testTimePicker:function(){
		setTimeout(function(){
			if ( this.dateTimePair1.getTimeValue() == "" ){
				this.dateTimePair1.forceOpenTime();			
			}
			else if ( this.dateTimePair2.getTimeValue() == "" ){
				this.dateTimePair2.forceOpenTime();				
			}
			else {
				window.location.href = window.location.href.split('?')[0] + '?ad=01%2F12%2F2016&at=06%3A00&dd=07%2F12%2F2016&dt=18%3A00';
			}
		}.bind(this),100);
	}
});

var ParkingDateTimePair = View.extend({
	events: {
		'focus .js--date': 'dateFocus',
		'blur .js--date': 'dateBlur'	
	},
	linkWith:function(dateTimePair){
		this.linkedPair = dateTimePair;
	},
	initialize: function(){
		this.dateFormField = this.find('.js--date-form-field');		
		this.date = this.find('.js--date');
		this.timeFormField = this.find('.js--time-form-field');
		this.timeSelect = this.find('.js--time-select');
		this.choices = new Choices(
			this.timeSelect,
			{ 
				placeholder:false,
				itemSelectText: ''
			}
		);
		this.on(this.timeFormField, 'change',this.choicesChange.bind(this));

		this.mode = Utils.hasClass(this.dateFormField, 'form__field--date--xdsoft') ? 
					ParkingDateTimePair.MODE.XDSOFT : 
					Utils.hasClass(this.dateFormField, 'form__field--date--pikaday') ? 
					ParkingDateTimePair.MODE.PIKADAY : -1;
		
		setTimeout(function(){
			this.choiceItems = this.find('.choices__list .choices__item');
			var itemCount = this.choiceItems.length;
			if ( this.choiceItems[itemCount-1] ) {
				Utils.remove(this.choiceItems[itemCount-1]);
			}
			this.choiceItems = this.find('.choices__list .choices__item');
			this.choiceInput = this.find('.choices__input--cloned');
			this.timeSelect.value = -1;

			Utils.each(this.choiceItems,function(elem){
				Utils.removeClass(elem, 'is-highlighted');
			} );
			
			Utils.addClass(this.find('.choices__list--single'),'is-ready');
			this.find('.choices__list--single .choices__item--selectable').innerHTML = '&nbsp;';

			if ( this.mode == ParkingDateTimePair.MODE.XDSOFT ) {
				this.datePicker = new DatePickerXDSoft({el:this.dateFormField, index:this.settings.index});
			}
			else if ( this.mode == ParkingDateTimePair.MODE.PIKADAY ) {
				this.datePicker = new DatePickerPikaday({el:this.dateFormField, index:this.settings.index});
			}
			else {
				throw new Error('NO DATE PICKER MODE SELECTED!');
			}

			setTimeout(function (){
				this.eventEmitter.on('datepicker:change', this.dateChangeHandler.bind(this));
			}.bind(this), 200);

		}.bind(this),400);
	},
	activate:function() {
		// Log.fn("ParkingDateTimePair | activate");
		this.datePicker.show();
	},
	forceOpenTime:function(){
		// Log.fn("ParkingDateTimePair | forceOpenTime | this",this.choices.showDropdown)
		this.choices.showDropdown();	
		setTimeout(function(){
			this.choiceInput.focus();
		}.bind(this),10000);
	},
	resolveDate: function(val) {
		if(!val) { return }
		return moment().add(val, 'days')._d;
	},
	dateChangeHandler:function(index) {	
		// Log.fn("ParkingDateTimePair | dateChangeHandler | index="+index+", settings.index="+this.settings.index);
		if ( index != this.settings.index ) return false;

		Utils.addClass(this.dateFormField,'is-filled');
		this.forceOpenTime();
	},
	dateFocus: function() {		
		if (Utils.hasClass(this.date,'is-disabled')) return;
		Utils.addClass(this.date,'has-focus');
		Utils.addClass(this.date,'is-filled');
	},
	dateBlur: function() {		
		// Utils.removeClass(this.date,'has-focus');
		// if ( this.date.value.length == 0) Utils.removeClass(this.date,'is-filled');
	},
	choicesChange:function(event) {
		// Log.db("ParkingDateTimePair | choicesChange", event)
		Utils.addClass(this.timeFormField,'is-filled');
		if ( this.linkedPair ) this.linkedPair.activate();
	},
	getTimeValue:function() {
		return this.timeSelect.value;
	}
});

ParkingDateTimePair.MODE = {XDSOFT:0,PIKADAY:1};

(function(){
	Utils.each(Utils.find('.js--parking-time-picker'), function(elem) {
		new ParkingBooking( {el: elem} );
	});
})();

module.exports = ParkingBooking;