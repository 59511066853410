'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var InjectionTest = View.extend({
	
	newHTML: '<div class="js--accordion" data-disposable="true"><a class="button button--link button--link--heavy js--accordion-trigger"><span class="text">Accordion 2 trigger</span></a><div class="js--accordion-content"><h4>Accordion 2 content</h4><p>Nunc et leo erat. Aenean mattis ultrices lorem, eget adipiscing dolor ultricies eu. In hac habitasse platea dictumst. Vivamus cursus feugiat sapien quis aliquam. Mauris quam libero, porta vel volutpat ut, blandit a purus. Vivamus vestibulum dui vel tortor molestie, sit amet feugiat sem commodo. Nulla facilisi. Sed molestie arcu eget tellus vestibulum tristique.</p></div></div>',

	events: {
		'click .js--inject':'injectHandler',
		'click .js--update':'updateHandler'
	},

	initialize:function() {
		this.target = this.find('.js--target');
	},

	injectHandler: function(event) {
		this.target.innerHTML = this.newHTML;

		window.dispatchEvent( new Event('accordion:init') );
		
		setTimeout(function(){
			window.dispatchEvent( new Event('accordion:updateheight') );
		}, 200);
	},

	updateHandler:function(event){
		window.dispatchEvent( new Event('accordion:updateheight') );
	}
});

(function(){
	Utils.each(Utils.find('.js--injection-test'), function(elem) {
		new InjectionTest( {el: elem} );
	});

})();

module.exports = InjectionTest;