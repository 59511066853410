'use strict';
var _ = require('underscore'),
	Hammer = require('hammerjs');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var PanningMap = View.extend({

	enabled: true,

	interacting: false,
	animationSpeed:800,
	panningOrigin:'center',
	
	panningOrigin:null,	
	innerPositionOrigin: {x:0,y:0},
	innerPositionNew: {x:0,y:0},
	innerTransform: 'translate3d(0,0,0)',
	innerScale: 1,
	innerPreviousScale: null,
	transformOrigin: {x:0,y:0},
	transformOriginStyles: '',
	maxScale: 2,
	imageSizeSet: false,
	savedWindowWidth:0,
	events: {
		'click .map-marker': 'markerClickHandler',		
		'click .js--mobile-activate': 'mobileActivateClickHandler',	
		'click .js--mobile-deactivate': 'mobileDeactivateClickHandler'		
	},

	initialize: function(){
		//Log.fn("PanningMap | initialize");
		this.panningInner = this.find('.js--panning-map-inner');
		this.panningDraggable = this.find('.js--panning-map-draggable');
		this.panningInteractive = this.find('.js--panning-map-interactive');
		this.panningInteractiveMarkers = this.find('.js--panning-map-interactive .map-marker');
		this.panningInteractiveLabels = this.find('.js--panning-map-interactive .map-label');
		this.panningImage = this.find('.js--panning-map-image');
		
		this.mobileOverlay = this.find('.js--mobile-overlay');
		this.mobileDeactivate = this.find('.js--mobile-deactivate');


		this.eventEmitter.once('map:activate', this.setImageSize.bind(this) );
		this.eventEmitter.on('map:deactivate', this.mobileDeactivateClickHandler.bind(this) );
		

		var hammertime = new Hammer(this.panningInner, {});
		hammertime.get('pinch').set({ enable: true });
		//hammertime.on('doubletap pinch pinchend', this.zoomHandler.bind(this));
		hammertime.on('doubletap', this.zoomHandler.bind(this));

		this.touchMode = Utils.hasClass(Utils.find('html')[0], 'touchevents');
		

		if ( window.navigator.pointerEnabled ) {
			//Log.db("[[[ POINTER MODE ]]]");
			Utils.on(this.panningInner, 'pointerdown', this.pointerDownHandler.bind(this) );
			Utils.on(document, 'pointermove', this.pointerMoveHandler.bind(this) );
			Utils.on(document, 'pointerup', this.pointerUpHandler.bind(this) );

			this.enabled = false;
		} 
		else if (window.navigator.msPointerEnabled) {
			//Log.db("[[[ MS POINTER MODE ]]]");
			Utils.on(this.panningInner, 'MSPointerDown', this.pointerDownHandler.bind(this) );				
			Utils.on(document, 'MSPointerMove', this.pointerMoveHandler.bind(this) );
			Utils.on(document, 'MSPointerUp', this.pointerUpHandler.bind(this) );

			this.enabled = false;
		}
		else if (this.touchMode) {
			//Log.db("[[[ TOUCH MODE ]]]");
			Utils.on(this.panningInner, 'touchstart', this.touchStartHandler.bind(this) );
			Utils.on(document, 'touchmove', this.touchMoveHandler.bind(this) );
			Utils.on(document, 'touchend', this.touchEndHandler.bind(this) );

			this.enabled = false;
		}
		else {
			//Log.db("[[[ MOUSE MODE ]]]");
			Utils.on(this.panningInner, 'mousedown', this.mouseDownHandler.bind(this) );
			Utils.on(document, 'mousemove', this.mouseMoveHandler.bind(this) );
			//Utils.on(this.el, 'mousewheel DOMMouseScroll', this.mouseWheelHandler.bind(this) );
			Utils.on(document, 'mouseup', this.mouseUpHandler.bind(this) );

			this.enabled = true;
		}
		this.resetTransformOrigin();
		this.adjustInteractives();

		Utils.on(window, 'resize', this.resizeHandler.bind(this) );
		this.resizeHandler();
		//this.test();
	},

	setImageSize:function() {
		if ( this.imageSizeSet ) return false;
		this.imageSizeSet = true;
		var imageStyles = 'width:'+this.panningImage.clientWidth+'px; height:'+this.panningImage.clientHeight+'px;';
		this.panningImage.setAttribute('style', this.panningImage.getAttribute('style') + imageStyles);
		this.resizeHandler();
	},

	resizeHandler:function() {
		var width = window.outerWidth;
		if ( this.savedWindowWidth === width ) return false;
		else {
			this.savedWindowWidth = width;
			this.mobileViewport = true;
			if ( width > 768 ) {
				this.mobileViewport = false;
			}
			if ( width >= 768 ) {
				this.enabled = true;
			}
			var CW = this.getCW(),
				CH = this.getCH(),
				DW = this.getDW(),
				DH = this.getDH();

			//Log.fn("DW="+DW+", DH="+DH+", CW="+CW+", CH="+CH+", this.innerScale="+this.innerScale);

			this.innerPositionNew.x = (CW-DW)*0.34;
			this.innerPositionNew.y = (CH-DH)*0.63;
			//Log.db(">> innerPositionNew x,y: "+this.innerPositionNew.x+","+this.innerPositionNew.y);
			
			this.constrain();
			this.adjustInteractives();
		}
	},


	markerClickHandler: function(event) {
		//marker clicked
	//	Log.fn("PanningMap | markerClickHandler");

		var target = Utils.closestByClass(event.target, 'marker');
		if ( Utils.hasClass(target, 'is-disabled') ) return false;

		Utils.toggleClass(target,'is-active');

		Utils.each(this.panningInteractiveMarkers, function(elem) {
			if ( elem != target ) Utils.removeClass(elem,'is-active');
		}.bind(this));

		this.interacting = false;
		this.panToMarker(target);
		/*
		this.innerPositionNew = { x: event.pageX,y: event.pageY};		
		this.constrain();
		*/
	},

	panToMarker:function(marker) {
		
		var markerDims = {w:320, h:200},
			markerMargin = 5,
			CW = this.getCW(),
			CH = this.getCH(),
			CO = this.getCO(),
			DO = this.getDO(),
			MO = this.offset(marker);

		MO.left -= CO.left;
		MO.top -= CO.top;

		if ( this.mobileViewport ) {
			//center marker in viewport
			this.innerPositionNew.x = DO.left - (CO.left + MO.left - CW/2 + markerDims.w/2 - markerMargin);
			this.innerPositionNew.y = DO.top - (CO.top + MO.top - CH/2 + markerDims.h/2);
		}
		else {
			var position = marker.dataset.position,
				markerAdjustHorizontal = -markerDims.w,
				markerAdjustVertical = -markerDims.h;

			switch (position) {
				case 'bottom-right':
					markerAdjustHorizontal = markerDims.w;
					markerAdjustVertical = markerDims.h;
					break;
				case 'bottom-left':
					markerAdjustHorizontal = -markerDims.w;
					markerAdjustVertical = markerDims.h;
					break;
				case 'top-right':
					markerAdjustHorizontal = markerDims.w;
					markerAdjustVertical = -markerDims.h;
					break;
			}
			
			if ( this.panningOrigin.x >= CW + markerAdjustHorizontal) {
				this.innerPositionNew.x = DO.left - ( CW + MO.left - markerAdjustHorizontal);
			}
			if ( this.panningOrigin.y >= CH + markerAdjustVertical) {
				this.innerPositionNew.y = DO.top - ( CH + MO.top - markerAdjustVertical );			
			}		


		}
		Utils.addClass(this.panningDraggable, 'is-animating');
		setTimeout(function(){
			Utils.removeClass(this.panningDraggable, 'is-animating');
		}.bind(this),300);
		this.constrain();
	},

	mobileActivateClickHandler:function() {
		this.enabled = true;
		Utils.removeClass(this.mobileOverlay,'is-active');
		Utils.addClass(this.mobileDeactivate,'is-active');
	},

	mobileDeactivateClickHandler:function() {
		this.enabled = false;
		Utils.addClass(this.mobileOverlay,'is-active');
		Utils.removeClass(this.mobileDeactivate,'is-active');
	},

	//MS POINTER EVENTS
	pointerDownHandler: function (event) {
		this.panningStart(event);
	},
	pointerMoveHandler: function (event) {
		this.panningUpdate(event);
	},
	pointerUpHandler: function (event) {
		this.panningStop();
	},	

	//TOUCH EVENTS
	touchStartHandler: function (event) {
		this.panningStart(event.targetTouches[0]);		
	},
	touchMoveHandler: function(event){
		this.panningUpdate(event.targetTouches[0]);
	},
	touchEndHandler: function(event){
		this.panningStop(event);
	},

	//MOUSE EVENTS
	mouseDownHandler: function (event) {
		//Log.fn("PanningMap | mouseDownHandler",this.enabled);
		
		this.panningStart(event);
	},
	mouseMoveHandler: function(event){
		this.panningUpdate(event);
	}, 
	mouseUpHandler: function(event) {
		this.panningStop(event);
	},



	panningStart:function(event){
	////	Log.fn("PanningMap | panningStart",this.enabled);
		
		if ( !this.enabled ) return false;

		var CO = this.getCO(),
			DO = this.getDO();
		this.panningOrigin = {y:event.pageY,x:event.pageX};
		this.innerPositionOrigin = {x:DO.left,y:DO.top};		
		//Log.fn(">> innerPositionOrigin before CO x,y: "+this.innerPositionOrigin.x+","+this.innerPositionOrigin.y);
		//Log.fn(">> panningOrigin before CO x,y: "+this.panningOrigin.x+","+this.panningOrigin.y);

		this.innerPositionOrigin.y -= CO.top;
		this.innerPositionOrigin.x -= CO.left;
		this.panningOrigin.y -= CO.top;
		this.panningOrigin.x -= CO.left;
		//Log.fn(">> innerPositionOrigin after CO x,y: "+this.innerPositionOrigin.x+","+this.innerPositionOrigin.y);
		//Log.fn(">> panningOrigin after CO x,y: "+this.panningOrigin.x+","+this.panningOrigin.y);
		
		this.interacting = true;
	},

	panningUpdate:function(event){
		if ( !this.enabled ) return false;
		
		if ( !this.interacting ) return false;

		var CO = this.getCO();
		this.innerPositionNew = { x: event.pageX + this.innerPositionOrigin.x + this.transformOrigin.x - this.panningOrigin.x - CO.left, y: event.pageY + this.innerPositionOrigin.y + this.transformOrigin.y - this.panningOrigin.y - CO.top };
		//Log.fn(">> innerPositionNew x,y: "+this.innerPositionNew.x+","+this.innerPositionNew.y);
		this.constrain();
	},

	panningStop:function(event) {
		if ( !this.enabled ) return false;
		
		//Log.fn("PanningMap | panningStop");
		this.interacting = false;
	},

	zoomHandler:function(event) {
		//Log.fn("PanningMap | zoomHandler", event);
		if ( !this.enabled ) return false;
		
		if (event.type == "doubletap") {
			if ( this.innerScale === 1 ) { 
				this.innerScale = 2;
			} else {
				this.innerScale = 1;
			}
			this.innerPreviousScale = this.innerScale;
		}
		if (event.type == "pinch") {
			this.innerScale = Math.max(.999, Math.min(this.innerPreviousScale * (event.scale), this.maxScale));
		}
		if (event.type == "pinchend") {
			this.innerPreviousScale = this.innerScale;
		}
	
		var touchPoint = event.changedPointers[0];

		var CO = this.getCO(),
			CW = this.getCW(),
			CH = this.getCH(),
			DW = this.getDW(),
			DH = this.getDH();
		
		this.transformOrigin = { x: DW * ((touchPoint.pageX - CO.left) / CW),
								 y: DH * ((touchPoint.pageY - CO.top) / CH) };

		this.adjustInteractives();
		this.setDraggableStyles();
		setTimeout(this.constrain.bind(this), 400);
	},

	adjustInteractives:function() {
	//	Log.fn("PanningMap | adjustInteractives | this.panningInteractiveMarkers???",this.panningInteractiveMarkers)
		var markerTransform = "scale3d(" + 1/this.innerScale + ", " + 1/this.innerScale + ", 1)";

		var style = "-webkit-transform: "+markerTransform+
					"; -ms-transform: "+markerTransform+
					"; -moz-transform: "+markerTransform+
					"; transform: "+markerTransform+";"

		Utils.each(this.panningInteractiveMarkers, function(elem) {
			var elemData = elem.dataset;
		//	Log.db("MARKER, x:"+elemData.positionx+", y:"+elemData.positiony)
			elem.setAttribute('style',style+elemData.positionstyle);

			Utils.removeClass(elem,'map-marker--bottom-right');
			Utils.removeClass(elem,'map-marker--bottom-left');
			Utils.removeClass(elem,'map-marker--top-right');

			if ( !this.mobileViewport ) {
				if ( elemData.positionx > 50 ) {
					if ( elemData.positiony > 50 ) {
						//BOTTOM RIGHT
					//	Log.db("BOTTOM RIGHT")
						elem.setAttribute('data-position','bottom-right');
						Utils.addClass(elem,'map-marker--bottom-right');
					}
					else {
						//TOP RIGHT
					//	Log.db("TOP RIGHT")
						elem.setAttribute('data-position','top-right');
						Utils.addClass(elem,'map-marker--top-right');
					}
				}
				else if ( elemData.positiony > 50 ) {
					//BOTTOM LEFT
				//	Log.db("BOTTOM LEFT")
					elem.setAttribute('data-position','bottom-left');
					Utils.addClass(elem,'map-marker--bottom-left');
				}
				else {
					elem.setAttribute('data-position','top-left');
					//TOP LEFT - default
				}
			}
		}.bind(this));

		Utils.each(this.panningInteractiveLabels, function(elem) {
			var elemData = elem.dataset;
			elem.setAttribute('style',style+elemData.positionstyle);
		}.bind(this));

	},


	constrain:function() {

		if ( this.innerScale === 1 ) { 
			this.resetTransformOrigin();
		}

		var CO = this.getCO(),
			CW = this.getCW(),
			CH = this.getCH(),
			DW = this.getDW(),
			DH = this.getDH();

		//Log.fn("DW="+DW+", DH="+DH+", CW="+CW+", CH="+CH+", this.innerScale="+this.innerScale);

		//Log.fn(">> transformOrigin x,y: "+this.transformOrigin.x+","+this.transformOrigin.y);
		//Log.fn(">> innerPositionNew before x,y: "+this.innerPositionNew.x+","+this.innerPositionNew.y);

		if (this.innerPositionNew.x < (CW - DW*this.innerScale)+this.transformOrigin.x ) {
			this.innerPositionNew.x = (CW - DW*this.innerScale)+this.transformOrigin.x;
		}
		if (this.innerPositionNew.x > this.transformOrigin.x) {
			this.innerPositionNew.x = this.transformOrigin.x;
		}
		if (this.innerPositionNew.y < (CH - DH*this.innerScale)+this.transformOrigin.y ) {
			this.innerPositionNew.y = (CH - DH*this.innerScale)+this.transformOrigin.y;
		}
		if (this.innerPositionNew.y > this.transformOrigin.y) {
			this.innerPositionNew.y = this.transformOrigin.y;
		}
		//Log.fn(">> innerPositionNew after x,y: "+this.innerPositionNew.x+","+this.innerPositionNew.y);
		
		this.setDraggableStyles();
	},

	setDraggableStyles:function() {		

		this.transformOriginStyles = "  -webkit-transform-origin: " + this.transformOrigin.x + "px " + this.transformOrigin.y + "px" +
									 "; -ms-transform-origin: " + this.transformOrigin.x + "px " + this.transformOrigin.y + "px" +
									 "; -moz-transform-origin: " + this.transformOrigin.x + "px " + this.transformOrigin.y + "px" +
									 "; transform-origin: " + this.transformOrigin.x + "px " + this.transformOrigin.y + "px;"
		
		this.innerTransform = "translate3d(" + this.innerPositionNew.x + "px, " + this.innerPositionNew.y+"px, 0) " +
							  "scale3d(" + this.innerScale + ", " + this.innerScale + ", 1)";

		var style = "-webkit-transform: " + this.innerTransform +
					"; -ms-transform: " + this.innerTransform +
					"; -moz-transform: " + this.innerTransform +
					"; transform: " + this.innerTransform + ";" + this.transformOriginStyles;

		this.panningDraggable.setAttribute('style', style);
	},

	resetTransformOrigin:function() {
		this.transformOrigin = {x:0,y:0};
	},

	getCW:function() {
		return this.el.offsetWidth;
	},
	getCH:function() {
		return this.el.offsetHeight;
	},
	getDW:function() {
		return this.panningDraggable.offsetWidth;
	},
	getDH:function() {
		return this.panningDraggable.offsetHeight;
	},
	getCO:function() {
		return this.offset(this.el);
	},
	getDO:function() {
		return this.offset(this.panningDraggable);
	},
	offset:function( elem ) {

		var docElem, win,
			box = { top: 0, left: 0, bottom:0, right:0 },
			doc = elem && elem.ownerDocument;

		docElem = doc.documentElement;

		if ( typeof elem.getBoundingClientRect !== typeof undefined ) {
			box = elem.getBoundingClientRect();
		}
		win = (doc != null && doc === doc.window) ? doc : doc.nodeType === 9 && doc.defaultView;
		return {
			top: box.top + win.pageYOffset - docElem.clientTop,
			left: box.left + win.pageXOffset - docElem.clientLeft,
			right: box.right + win.pageXOffset - docElem.clientLeft,
			bottom: box.bottom + win.pageYOffset - docElem.clientTop
		};
	},


	tweenTo: function(el, prop, to, duration, easing, overwrite){

	  if(!el._mTween){el._mTween={top:{},left:{}};}
	  var startTime=_getTime(),_delay,progress=0,from=el.offsetTop,elStyle=el.style,_request,tobj=el._mTween[prop];
	  if(prop==="left"){from=el.offsetLeft;}
	  var diff=to-from;
	  if(overwrite!=="none"){_cancelTween();}
	  _startTween();
	  

	  function _step(){
		progress=_getTime()-startTime;
		_tween();
		if(progress>=tobj.time){
		  tobj.time=(progress>tobj.time) ? progress+_delay-(progress-tobj.time) : progress+_delay-1;
		  if(tobj.time<progress+1){tobj.time=progress+1;}
		}
		if(tobj.time<duration){tobj.id=_request(_step);}
	  }
	  function _tween(){
		if(duration>0){
		  tobj.currVal=_ease(tobj.time,from,diff,duration,easing);
		  elStyle[prop]=Math.round(tobj.currVal)+"px";
		}else{
		  elStyle[prop]=to+"px";
		}
	  }
	  function _startTween(){
		_delay=1000/60;
		tobj.time=progress+_delay;
		_request=(!window.requestAnimationFrame) ? function(f){_tween(); return setTimeout(f,0.01);} : window.requestAnimationFrame;
		tobj.id=_request(_step);
	  }
	  function _cancelTween(){
		if(tobj.id==null){return;}
		if(!window.requestAnimationFrame){clearTimeout(tobj.id);
		}else{window.cancelAnimationFrame(tobj.id);}
		tobj.id=null;
	  }
	  function _ease(t,b,c,d,type){
		var ts=(t/=d)*t,tc=ts*t;
		return b+c*(0.499999999999997*tc*ts + -2.5*ts*ts + 5.5*tc + -6.5*ts + 4*t);
	  }
	  function _getTime(){
		if(window.performance && window.performance.now){
		  return window.performance.now();
		}else{
		  if(window.performance && window.performance.webkitNow){
			return window.performance.webkitNow();
		  }else{
			if(Date.now){return Date.now();}else{return new Date().getTime();}
		  }
		}
	  }
	}
});

(function(){
	
	Utils.each(Utils.find('.js--panning-map'), function(elem) {	
		new PanningMap( {el: elem} );
	});

})();

module.exports = PanningMap;
