'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var HeroVideo = View.extend({
	initialize: function(){
		var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
		if ( iOS ) this.addClass('is-ios');
	}
});

(function(){
	Utils.each(Utils.find('.js--hero-video'), function(elem) {
		new HeroVideo( {el: elem} );
	});

})();

module.exports = HeroVideo;