'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var ShopForm = View.extend({
	
	events: {
		'input .js--destination': 'destinationInputHandler'
	},
	initialize:function() {
		this.destinationInput = this.find('.js--destination .form__item');
		this.navButtonHomeDelivery = this.find('.js--nav-home-delivery');	
	},

	destinationInputHandler:function() {
		Utils.toggleClass(this.navButtonHomeDelivery,'is-disabled', this.destinationInput.value.length > 0);
	}
});

(function(){
	Utils.each(Utils.find('.js--shop-form'), function(elem) {
		new ShopForm( {el: elem} );
	});

})();

module.exports = ShopForm;