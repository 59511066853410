'use strict';
var _ = require('underscore'),
	Quantity = require('../components/quantity');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var BasketProduct = View.extend({
		
	events: {		
		'click .js--remove-product': 'onRemove'
	},
	initialize:function() {
		this.quantityLimitText = this.find('.js--quantity-limited');
		this.statusPositive = this.find('.js--status-positive');
		this.statusNegative = this.find('.js--status-negative');

		var quantityEl = this.find('.js--quantity');
		if ( quantityEl instanceof HTMLElement ) {
			this.quantity = new Quantity( {el: quantityEl, updateCallback:this.quantityUpdate.bind(this)} );
		}		
	},
	onRemove:function(event) {
		this.addClass('is-deleted');
		setTimeout(function() { 
			this.testForBasketNotice();
			Utils.remove(this.el);
		}.bind(this), 300);


	},
	testForBasketNotice: function() {
		var nextElement = this.el.nextElementSibling ? this.el.nextElementSibling : null;
		if ( nextElement instanceof HTMLElement && Utils.hasClass(nextElement, 'basket-notice') ) {
			Utils.remove(nextElement);
		}
	},
	quantityUpdate:function(value) {
		/*
		if ( this.statusPositive instanceof HTMLElement && this.statusNegative instanceof HTMLElement) {
			Utils.toggleClass(this.quantityLimitText, 'is-active', value > 3 );
			Utils.toggleClass(this.statusPositive, 'is-active', value <= 3 );
			Utils.toggleClass(this.statusNegative, 'is-active', value > 3 );

			this.eventEmitter.emit('delivery:update', value <= 3);			
		}*/
	}
	
});

(function(){
	Utils.each(Utils.find('.js--basket-product'), function(elem) {
		new BasketProduct( {el: elem} );
	});

})();

module.exports = BasketProduct;