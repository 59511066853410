'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var MapsPeopleMap = View.extend({	

	googleMapsInstance: null,
	mapsIndoorsInstance: null,

	locationService: null,

	initialZoom: 19,

	initialCoords: { lat: 55.6287, lng: 12.647 },

	events: {
		"click .js--route-next": "nextLegHandler",
		"click .js--route-prev": "prevLegHandler"
	},

	initialize:function() {

		this.mapElement = this.find('.js--map');
		this.initData = window[this.el.getAttribute('data-id')];
		
		// Setup google map
		var gmStyles = [
			{ "stylers": [{ "visibility": "off" }] },
			{ "featureType": "water", "stylers": [{ "visibility": "on" }] },
			{ "featureType": "poi", "stylers": [{ "visibility": "off" }] },
			{ "featureType": "transit", "stylers": [{ "visibility": "on" }] },
			{ "featureType": "landscape", "stylers": [{ "visibility": "on" }] },
			{ "featureType": "road", "stylers": [{ "visibility": "on" }] },
			{ "featureType": "administrative", "stylers": [{ "visibility": "on" }] }
		];
		this.googleMapsInstance = new google.maps.Map(this.mapElement, { center: this.initialCoords, zoom: this.initialZoom, styles:gmStyles });

		// Setup MapsIndoors
		this.mapsIndoorsInstance = new mapsindoors.MapsIndoors({ map: this.googleMapsInstance });
		
		this.mapsIndoorsInstance.setLabelStyle({
			color: '#333',
			shadowBlur: 1,
			shadowColor: '#fff'
		});
		var floorSelectorContainer = document.createElement('div'),
			floorSelector = new mapsindoors.FloorSelector(floorSelectorContainer, this.mapsIndoorsInstance);

		this.googleMapsInstance.controls[google.maps.ControlPosition.RIGHT_TOP].push(floorSelectorContainer);

		this.locationsService = new mapsindoors.LocationsService();
		
		// Log.db("MapsPeopleMap | try to get categories");
		this.locationsService.getCategories().then(function(categories) {
			// Log.db("MapsPeopleMap | categories",categories);
		});

		// Log.db("MapsPeopleMap | try to get types");
		this.locationsService.getTypes().then(function(types) {
			// Log.db("MapsPeopleMap | types",types);		   
		});
		
		// Log.db("MapsPeopleMap | try to get locations");
		this.locationsService.getLocations().then(function(locations) {
			// Log.db("MapsPeopleMap | locations",locations);
			
			// //LOCATIONS READY, LOAD MAP CONTENT
			if ( this.initData && this.initData.route ) this.loadRoute();
			else if ( this.initData && this.initData.poi ) this.loadPOIs();
			else this.mapsIndoorsInstance.setFloor(1);

		}.bind(this));

		var moduleScope = this;
		google.maps.event.addListener(this.mapsIndoorsInstance, 'location_click', function(location) {
			var markerScope = this;
			moduleScope.poiClickHandler.call(moduleScope, location);
		});



		var externalPoiButtons = Utils.find('.js--find-poi');
		Utils.each(externalPoiButtons, function(elem) {
			Utils.on(elem, 'click', function(){
				var poiId = elem.getAttribute('data-poi');
				this.poiFound(poiId, this.initData.poi.zoom);
				
				Utils.each(externalPoiButtons, function(elem) {
					Utils.removeClass(elem,'is-active');
				}.bind(this));
				Utils.addClass(elem,'is-active');

			}.bind(this));
		}.bind(this));

	},
	poiClickHandler: function(location) {
		Log.fn("MapsPeopleMap | poiClickHandler | location?",location);

		var contentString = '<div class="indoors-map__popout">';
		contentString +=  '<h3>'+location.properties.name+'</h3>';
		
		if ( this.initData.detailedInfoWindow ) {

			var hasExtraFields = !(_.isEmpty(location.properties.fields)),
				description = this.getField(location.properties.description),
				floorName = this.getField(location.properties.floorName);

			if ( hasExtraFields ) {

				var fields = location.properties.fields,
					terminalValue = this.getFieldValue(fields.terminal),
					openingHoursValue = this.getFieldValue(fields.openinghours),
					phoneValue = this.getFieldValue(fields.phone),
					phoneText = this.getFieldText(fields.phone),
					emailValue = this.getFieldValue(fields.email),
					emailText = this.getFieldText(fields.email);

				terminalValue ? contentString += '<p class="bodytext-sm">Terminal: ' + terminalValue + '</p>' : null;
				floorName ? contentString += '<p class="bodytext-sm">Etage: ' + floorName + '</p>' : null;
				terminalValue || floorName ? contentString += '<br/>' : null;

				description ? contentString += '<p class="bodytext-xs">' + description + '</p><br/>' : null;
				openingHoursValue ? contentString += '<p class="bodytext-sm">'+openingHoursValue+'</p><br/>' : null;
				phoneValue || emailValue ? contentString += '<p class="bodytext-sm">' : null;
				
				phoneValue ? contentString += '<span>'+phoneText+':</span> <em>'+phoneValue+'</em>' : null;
				emailValue ? contentString += '<br/><span>'+emailText+':</span> <em>'+emailValue+'</em>' : null;

				phoneValue || emailValue ? contentString += '</p>' : null;

			}
			else {
					
				description ? contentString += '<p class="bodytext-xs">' + description + '</p>' : null;
			}
		
		}
		
		contentString += '</div>';

        if ( this.currentInfoWindow ) {
        	try {
        		this.currentInfoWindow.close();
        	}
        	catch (error){}
        }

		this.currentInfoWindow = new google.maps.InfoWindow({
			content: contentString, 
			maxWidth: 250,
			maxHeight: 250
		});

		this.currentInfoWindow.setPosition( new google.maps.LatLng(location.geometry.coordinates[1], location.geometry.coordinates[0]));
		this.currentInfoWindow.open(this.googleMapsInstance);
		

		google.maps.event.addListener(this.currentInfoWindow, 'domready', function() {

			var iw = Utils.find('.gm-style-iw')[0];
			var iwBackground = iw.previousElementSibling;
			var iwArrow = iwBackground.children[2];
			var iwOuter = iw.parentNode;

			iwBackground.children[0].style.display = 'none';
			iwBackground.children[1].style.display = 'none';
			iwBackground.children[3].style.display = 'none';

			Utils.addClass(iwArrow, 'gm-style-iw__arrow');
			Utils.addClass(iwOuter, 'gm-style-iw__outer');
		});

		var poiEvent = new CustomEvent('indoorsmaps:poiclick', { detail: {id:location.id} });
		window.dispatchEvent(poiEvent);
	},

	poiFound: function(poiId, zoom) {
		// Log.fn("poiFound",poiId, zoom);
		this.locationsService.getLocation(poiId).then(function(detailedData){
			this.poiClickHandler(detailedData);
			this.googleMapsInstance.setCenter(new google.maps.LatLng(detailedData.geometry.coordinates[1], detailedData.geometry.coordinates[0]));
			if ( zoom ) this.googleMapsInstance.setZoom(zoom);
		}.bind(this));
	},

	loadPOIs: function() {
		// Log.db("MapsPeopleMap | this.mapsIndoorsInstance",this.mapsIndoorsInstance.map.__gm.R);
		var poiData = this.initData.poi;
		// Log.fn("MapsPeopleMap | loadPOIs", "poiData", poiData);

		if ( poiData.excludeOthers ) {
			this.mapsIndoorsInstance.setLocationsVisible(false);
		}
		if ( poiData.floor ) {
			this.mapsIndoorsInstance.setFloor(poiData.floor);
		}

		// thiscurrentBounds = new google.maps.LatLngBounds();

		// var poiCount = this.initData.poi.list ? this.initData.poi.list.length : 0;
		// if ( poiCount > 0 ) {

		// 	this.mapsIndoorsInstance.setFloor(this.initData.poi.list[0].floor);

		// 	for ( var i = 0; i < poiCount; i++) { 
		// 		var poi = this.initData.poi.list[i];
		// 		if ( poiCount == 1 ) {
		// 			var moduleScope = this;
		// 			this.mapsIndoorsInstance.find(poi.id).then(function(){
		// 				this.locationsService.getLocation(poi.id).then(
		// 					function(detailedData){
		// 						this.poiClickHandler(detailedData);
		// 						this.fitBounds(detailedData);
		// 					}.bind(this));
		// 			}.bind(this));
		// 		}
		// 		else {
		// 			this.mapsIndoorsInstance.find(poi.id).then(this.fitBounds.bind(this));
		// 		}
		// 	}
		// }

		if ( poiData.id) {
			this.mapsIndoorsInstance.find(poiData.id).then(function(){
				this.poiFound(poiData.id, poiData.zoom);
			}.bind(this));
		}
		else if ( poiData.categories ) {
			var categoryCount = poiData.categories.length;
			// Log.db("MapsPeopleMap | categoryCount="+categoryCount)
			
			this.mapsIndoorsInstance.setFloor(poiData.floor);

			switch (categoryCount) {
				case 1:
					this.mapsIndoorsInstance.find({categories: poiData.categories});
				break;
				case 2: 
					this.locationsService.getLocations().then(function(locations) {
						var filtered = locations.filter(function(l){
							return l.properties.categories[poiData.categories[0]] && l.properties.categories[poiData.categories[1]];
						});
						filtered.forEach(function(l) {
							this.mapsIndoorsInstance.find(l.id);
						}.bind(this));
					}.bind(this));
				break;
				case 3:
					this.locationsService.getLocations().then(function(locations) {
						var filtered = locations.filter(function(l){
							return l.properties.categories[poiData.categories[0]] && l.properties.categories[poiData.categories[1]] && l.properties.categories[poiData.categories[2]];
						});
						filtered.forEach(function(l) {
							this.mapsIndoorsInstance.find(l.id);
						}.bind(this));

					}.bind(this));
				break;
			}

			if ( poiData.zoom ) setTimeout(function(){
				this.googleMapsInstance.setZoom(poiData.zoom);
			}.bind(this),4000);

		}
		else if ( this.initData.poi.query ) {
			// Log.db("MapsPeopleMap | this.mapsIndoorsInstance.find({q: '"+poiData.query+"');")
			this.mapsIndoorsInstance.find({q: poiData.query});
		}

	},


	fitBounds: function(location) {
		// Log.fn("MapsPeopleMap | fitBounds | location?",location);
		this.currentBounds.extend( new google.maps.LatLng(location.geometry.coordinates[1], location.geometry.coordinates[0]) );
		this.googleMapsInstance.fitBounds(this.currentBounds);		
	},

	loadRoute: function() {
		// Log.fn("MapsPeopleMap | loadRoute | this.initData.route?", this.initData.route)
		var legCount = this.initData.route.legs ? this.initData.route.legs.length : 0;

		if ( legCount > 0 ) {			
			for ( var i = 0; i < legCount; i++) {  
				var leg = this.initData.route.legs[i];

				var directionsService = new mapsindoors.DirectionsService();
				var directionsRenderer = new mapsindoors.DirectionsRenderer({ map: this.googleMapsInstance, mapsindoors: this.mapsIndoorsInstance});
				
				directionsRenderer.setStyle('default', {
					strokeColor: "#fecb00",
					strokeOpacity: 1.0,
					strokeWeight: 10
				});

				directionsRenderer.setStyle('hidden', {
					strokeColor: "#fecb00",
					strokeOpacity: 0.2,
					strokeWeight: 10
				});

				directionsRenderer.setStyle('inactive', {
					visible: false
				});

				directionsService.route(leg).then(		
					function (routeData) {
						// Log.db("MapsPeopleMap | directionsRenderer.setDirections(routeData)")
						// Log.db("MapsPeopleMap | routeData?",routeData)
						directionsRenderer.setDirections(routeData);
						//this.setupRouteMarkers(directionsRenderer, routeData);
						directionsRenderer.setLegIndex(0);				
					}.bind(this)
				);
			}
		}
		
		this.mapsIndoorsInstance.setFloor(this.initData.route.legs[0].origin.floor);
	},

	nextLegHandler: function() {
	},
	prevLegHandler: function() {
	},

	setupRouteMarkers: function(directionsRenderer, routeData) {
		var routeStartMarker = new google.maps.Marker({map: this.googleMapsInstance});
		var routeEndMarker = new google.maps.Marker({map: this.googleMapsInstance});

		var currentRoute = routeData.routes[0];

		function setLeg(index) {
			var currentLeg = currentRoute.legs[index];
			routeStartMarker.setPosition(currentLeg.end_location);
			routeEndMarker.setPosition(currentLeg.destination);
			directionsRenderer.setLegIndex(index);
		}

		setLeg(0);
		
		google.maps.event.addListener(routeEndMarker, 'click', function() {
			// Log.db("MapsPeopleMap | routeEndMarker click")
			setLeg(++index);
		}.bind(this));
	},

	getFieldValue: function(prop) {
		if ( prop && prop.value != "" ) return prop.value;
		return null;
	},

	getFieldText: function(prop) {
		if ( prop && prop.text != "" ) return prop.text;
		return null;
	},

	getField: function(prop) {
		if ( prop && prop != "" ) return prop;
		return null;
	}

});

(function(){
	
	function mapsLoadHandler() {
		window.googleMapsLoaded = true;
		Utils.each(Utils.find('.js--maps-people-map'), function(elem) {
			new MapsPeopleMap( {el: elem} );
		});
	}
	if ( window["google"] && !window.googleMapsLoaded ) {
		google.maps.event.addDomListener(window, 'load', mapsLoadHandler);
	}
	else {
		mapsLoadHandler();
	}

})();

module.exports = MapsPeopleMap;