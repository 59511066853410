'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;
	
var Notification = View.extend({
	events: {
		'click .button--icon': 'close'
	},
	close: function() {
		this.remove();
	}
});

(function(){
	Utils.each(Utils.find('.js--notification'), function(elem) {
		new Notification( {el: elem} );
	});

})();

module.exports = Notification;