'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;
	
var ParcelBoxes = View.extend({
	triggered:false,
	events: {
		'input .form__item': 'changeHandler'
	},
	initialize:function() {
		this.parcelBoxRadios = this.find('.js--parcel-box-radios');
	},
	changeHandler: function() {
		if ( this.triggered ) return false;
		this.triggered = true;

		setTimeout(function(){
			this.parcelBoxRadios.setAttribute('style','display:block;');
			setTimeout(function(){Utils.addClass(this.parcelBoxRadios,'is-active');}.bind(this), 50);
		}.bind(this), 1000);
	}
});

(function(){
	Utils.each(Utils.find('.js--parcel-boxes'), function(elem) {
		new ParcelBoxes( {el: elem} );
	});

})();

module.exports = ParcelBoxes;