'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var AlphabeticList = View.extend({
	events: {
		'click .js--toggle': 	'toggleButtonClickHandler',
		'click .js--show-all': 	'showAllButtonClickHandler'
	},
	initialize: function() {
		this.items = this.find('.js--item');
		this.togglers = this.find('.js--toggle');
		this.showAll = this.find('.js--show-all');
		this.content = this.find('.js--list-content')
	},
	toggleButtonClickHandler: function(e) {
		var toggler = e.currentTarget;
		if (Utils.hasClass(toggler,'is-disabled')) return false;
		var allContentVisible = Utils.hasClass(this.content,'is-expanded');
		var char = toggler.getAttribute('data-char');

		Utils.each(this.items,function(item){
			if ( item.getAttribute('data-char') != char ){ 
				if ( allContentVisible ){
					Utils.addClass(item,'is-hiding');					
					setTimeout(function(){
						Utils.removeClass(item, 'is-hiding');
						Utils.addClass(item,'is-disabled');
					},100);
				}
				else {
					Utils.addClass(item,'is-disabled');				
				}			
			}
			else {
				if ( !allContentVisible ) Utils.addClass(item,'is-showing');				
				Utils.removeClass(item,'is-disabled');
				setTimeout(function(){Utils.removeClass(item, 'is-showing');},100);
			}
		}.bind(this));

		Utils.each(this.togglers,function(toggler){
			Utils.removeClass(toggler,'is-active');
		}.bind(this));		
		Utils.addClass(toggler,'is-active');

		Utils.addClass(this.showAll,'is-active');	

		Utils.removeClass(this.content,'is-expanded');	
	},
	showAllButtonClickHandler: function(e) {
		Utils.each(this.items,function(item){
			Utils.removeClass(item, 'is-disabled');		
			Utils.addClass(item,'is-showing');
			setTimeout(function(){Utils.removeClass(item, 'is-showing');},200);
		}.bind(this));
		
		Utils.each(this.togglers,function(toggler){
			Utils.removeClass(toggler,'is-active');
		}.bind(this));

		Utils.removeClass(this.showAll,'is-active');		
		Utils.addClass(this.content,'is-expanded');
	}
});

(function(){
	Utils.each(Utils.find('.js--alphabetic-list'), function(elem) {
		new AlphabeticList( {el: elem} );
	});
})();


module.exports = AlphabeticList;