'use strict';
var _ = require('underscore');
	
var View = require('kerneljs').View,
	Log = require('kerneljs').Log,
	Utils = require('kerneljs').Utils;

var CheckoutBasketParking = View.extend({
	
	isOpen:false,
	
	events: {
		'click .js--edit-time': 'onEditTime',		
		'click .js--edit-overlay-close': 'onCloseOverlay',
		'click .js--edit-overlay-submit': 'onEditTimeSubmit'
	},

	initialize: function(){
		this.editTimeOverlay = this.find('.js--edit-overlay');
	},

	onEditTime:function() {	
		Utils.showElement(this.editTimeOverlay);
	},
	onCloseOverlay:function() {	
		Utils.hideElement(this.editTimeOverlay);
	},
	onEditTimeSubmit:function() {
		this.onCloseOverlay();
	},
});

(function(){
	Utils.each(Utils.find('.js--checkout-basket-parking'), function(elem) {
		new CheckoutBasketParking( {el: elem} );
	});
})();
module.exports = CheckoutBasketParking;